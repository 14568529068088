import {
    AttendanceStatus,
    ClassAttendance,
    CourseProps,
    Evaluation,
    EvaluationStatus,
    LearningActivity,
    LmsType,
    Registration,
    RegistrationStatus,
    Schedule,
    Transcript
} from './types';
import {getCourseDetailsByCourseName} from './testData';

export const convertLearningActivityToCourse = (learningActivity: LearningActivity): CourseProps => {
    const schedule: Schedule = {
        classDays: learningActivity.delivery_sessions.map((deliverySession) => ({
            // TypeScript expects milliseconds, but the Grimsby timestamp is in seconds
            startDateTime: new Date(deliverySession.start_timestamp * 1_000),
            endDateTime: new Date(deliverySession.end_timestamp * 1_000)
        })),
        language: learningActivity.delivery_language,
        timezone: learningActivity.delivery_timezone,
        seatsRemaining: (learningActivity.class_size ?? 0) - learningActivity.registrationCounter
    };
    const courseDetails = getCourseDetailsByCourseName(learningActivity.course_name);
    return {
        id: learningActivity.pk,
        courseName: learningActivity.course_name,
        courseStatus: learningActivity.activity_status,
        classroomUrl: learningActivity.classrooms_student_url,
        nectoLink: learningActivity.v_ilt_id,
        schedule,
        ...courseDetails
    };
};

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export const convertLearningActivitiesToCourses = (learningActivitiesResult: any) => {
    const registrations: Registration[] = learningActivitiesResult.registrationsByUserId.nodes;
    const attendances: ClassAttendance[] = learningActivitiesResult.classAttendance;
    const learningActivities: LearningActivity[] = learningActivitiesResult.learningActivities;
    const transcripts: Transcript[] = learningActivitiesResult.transcriptsByUserId.nodes;
    const evaluations: Evaluation[] = learningActivitiesResult.iltEvaluationsByUserId.nodes;
    return learningActivities
        .filter((learningActivity) => learningActivity.lms_type === LmsType.myClass)
        .map((learningActivity) => {
            const registration = registrations.find((reg) => reg.learningActivity.pk === learningActivity.pk);
            const registrationStatus = registration ? registration.registrationStatus : RegistrationStatus.WITHDRAWN;
            const attendance = attendances.find((attendance) => attendance.learningActivity.pk === learningActivity.pk);
            const attendanceStatus = attendance
                ? attendance.learningActivityAttendanceStatus
                : AttendanceStatus.NO_SHOW;
            const transcript = transcripts.find((transcript) => transcript.learningActivity.pk === learningActivity.pk);
            const evaluation = evaluations.find((evaluation) => evaluation.learningActivity.pk === learningActivity.pk);

            return {
                ...convertLearningActivityToCourse(learningActivity),
                registrationStatus: registrationStatus,
                attendanceStatus: attendanceStatus,
                transcriptId: transcript?.id,
                evaluationStatus: evaluation?.evaluationStatus ?? EvaluationStatus.NOT_REQUIRED,
                evaluationUrl: evaluation?.evaluationUrl
            };
        });
};
