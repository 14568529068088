import { Box, Icon, SpaceBetween } from '@amzn/awsui-components-react';
import React from 'react';
import { IconProps } from '../../common/types';

export const LevelIcon = ({ value, size = 'small' }: IconProps) => (
    <SpaceBetween size={size == 'small' ? 'xxs' : 'xs'} direction='horizontal'>
        <Icon
            svg={
                <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' focusable='false' key='0'>
                    <g>
                        <line x1='4' y1='12' x2='4' y2='15' />
                        <line x1='7' y1='9' x2='7' y2='15' />
                        <line x1='10' y1='6' x2='10' y2='15' />
                        <line x1='13' y1='3' x2='13' y2='15' />
                    </g>
                </svg>
            }
            size={size == 'small' ? 'small' : 'medium'}
            variant={size == 'small' ? 'subtle' : 'normal'}
            alt='Level'
            data-testid='level'
        />
        <Box
            color={size == 'small' ? 'text-status-inactive' : 'inherit'}
            variant='div'
            fontSize={size == 'small' ? 'heading-xs' : 'heading-m'}
            fontWeight={size == 'small' ? 'normal' : 'bold'}
        >
            <h3>{value}</h3>
        </Box>
    </SpaceBetween>
);
