import {gql, useLazyQuery, useQuery} from '@apollo/client';

export const GET_CURRENT_USER = gql`
    query GetCurrentUser {
        currentUser {
            id
            emailAddress
            firstName
            lastName
            status
            userRole
            auditMetadata {
                createdAt
            }
        }
    }
`;

export const useGetCurrentUserLazy = () => {
    return useLazyQuery(GET_CURRENT_USER, {});
};

export const useGetCurrentUser = () => {
    return useQuery(GET_CURRENT_USER, {});
};
