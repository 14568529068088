import React from 'react';
import { Icon } from '@amzn/awsui-components-react';
import './ClassSchedule.scss';
import { isInProgress } from '../../common';
import { ClassDay, RegistrationStatus, Schedule } from '../../common';

export interface ClassScheduleProps {
    schedule: Schedule;
    registrationStatus?: RegistrationStatus;
}

export const ClassSchedule: React.FC<ClassScheduleProps> = (props: ClassScheduleProps) => {
    if (!props.schedule) {
        return null;
    }

    const getClassNameInProgress = (day: ClassDay) => {
        let className = 'cell';
        if (isInProgress(day.startDateTime) && props.registrationStatus === RegistrationStatus.REGISTERED) {
            className += ' inProgress';
        }
        return className;
    };

    const getClassNameBorder = (day: ClassDay, index: number) => {
        let className = getClassNameInProgress(day);
        if (index === 0) {
            className += ' left';
        }
        if (index === props.schedule.classDays.length - 1) {
            className += ' right';
        }
        return className;
    };

    return (
        <div className='background'>
            <table className='centered'>
                <tbody>
                    <tr className='top-row'>
                        {props.schedule.classDays.map((day, index) => (
                            <td key={index} className={getClassNameBorder(day, index)} data-testid={`day-${index}`}>
                                <h4>DAY {index + 1}</h4>
                            </td>
                        ))}
                    </tr>
                    <tr className='middle-row'>
                        {props.schedule.classDays.map((day, index) => (
                            <td key={index} className={getClassNameInProgress(day)} data-testid={`date-${index}`}>
                                <b>
                                    {day.startDateTime.toLocaleDateString('en-US', {
                                        month: 'short',
                                        day: '2-digit',
                                        timeZone: props.schedule.timezone,
                                    })}
                                </b>
                            </td>
                        ))}
                    </tr>
                    <tr className='bottom-row'>
                        {props.schedule.classDays.map((day, index) => (
                            <td key={index} className={getClassNameBorder(day, index)} data-testid={`time-${index}`}>
                                {day.startDateTime.toLocaleString('en-US', {
                                    hour: 'numeric',
                                    minute: 'numeric',
                                    hour12: true,
                                    timeZone: props.schedule.timezone,
                                })}
                                {' - '}
                                {day.endDateTime.toLocaleString('en-US', {
                                    hour: 'numeric',
                                    minute: 'numeric',
                                    hour12: true,
                                    timeZone: props.schedule.timezone,
                                })}
                            </td>
                        ))}
                    </tr>
                </tbody>
            </table>
            <div className='bottom'>
                <Icon size='small' name='contact' alt='Language' />
                <span data-testid='language'>{props.schedule.language}</span>
                <Icon name='status-pending' size='small' alt='Timezone' />
                <span data-testid='timezone'>{props.schedule.timezone}</span>
            </div>
        </div>
    );
};
