import { Badge } from '@amzn/awsui-components-react';
import React from 'react';

export function ProxyRegisteredBadge() {
    return (
        <Badge color='red'>
            <div aria-label='proxy registration' data-testid='proxyRegisteredBadge'>
                Proxy Registered
            </div>
        </Badge>
    );
}
