import {
    AppLayout,
    Badge,
    ContentLayout,
    Flashbar,
    Grid,
    Header,
    SpaceBetween,
    Spinner,
    Tabs
} from '@amzn/awsui-components-react';
import React, {useContext, useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {Navigation} from '../../components/navigation/Navigation';
import {getBreadcrumbItemsFromCourse} from '../../common/utils';
import './Course.scss';
import {CourseProps} from '../../common/types';
import {
    NotificationContext,
    DeliveryDetails,
    DurationIcon,
    LevelIcon,
    CategoryIcon,
    Breadcrumbs
} from '../../components';
import {CourseDetails} from './CourseDetails';
import {CourseOutline} from './CourseOutline';
import {PAGE_NOT_FOUND_ROUTE} from '../../common/routes';
import {CourseContext} from '../../components/course/CourseContext';

export const Course = () => {
    const {id} = useParams<{id: string}>();
    const [course, setCourse] = useState<CourseProps>();
    const {courses} = useContext(CourseContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (courses.length) {
            const course = courses.find((course) => course.id === id);
            if (!course) {
                navigate(PAGE_NOT_FOUND_ROUTE);
            } else {
                setCourse(course);
                document.title = course.courseName;
            }
        }
    }, [courses, id]);

    const content = () => (
        <ContentLayout
            header={
                course ? (
                    <Grid gridDefinition={[{colspan: {s: 8, xxs: 12}}, {colspan: {s: 4, xxs: 12}}]}>
                        <SpaceBetween size='xxl'>
                            <Badge color='green'>Virtual Classroom</Badge>
                            <h1
                                aria-label='Class Title'
                                style={{
                                    height: '200px',
                                    fontSize: '64px',
                                    fontWeight: 900,
                                    margin: 0,
                                    lineHeight: '64px'
                                }}
                            >
                                {course?.courseName}
                            </h1>
                            <SpaceBetween size='xxl' direction='horizontal' className='icons'>
                                <DurationIcon value={course?.duration} size='large' />
                                <LevelIcon value={course?.skillLevel} size='large' />
                                <CategoryIcon value={course?.category} size='large' />
                            </SpaceBetween>
                        </SpaceBetween>
                        <div className='details'>
                            <div className='wrapper'>{course && <DeliveryDetails course={course} />}</div>
                        </div>
                    </Grid>
                ) : (
                    <Header>
                        <Spinner size='large' />
                        <div>Loading Course data</div>
                    </Header>
                )
            }
        >
            <div className='content'>
                <Tabs
                    tabs={[
                        {
                            label: 'About',
                            id: 'first',
                            content: <>{course && <CourseDetails course={course} />}</>
                        },
                        {
                            label: 'Course outline',
                            id: 'second',
                            content: <>{course && <CourseOutline course={course} />}</>
                        }
                    ]}
                />
            </div>
        </ContentLayout>
    );
    const {notifications} = useContext(NotificationContext);

    return (
        <AppLayout
            content={content()}
            navigation={<Navigation />}
            contentType={'wizard'}
            notifications={<Flashbar items={notifications} />}
            toolsHide={true}
            breadcrumbs={<Breadcrumbs items={getBreadcrumbItemsFromCourse(course?.courseName ?? '')} />}
        />
    );
};
