import { CatalogItem, CourseDetails } from '../common/types';

/**
 * Helper to map from external models into an internal CourseDetails model.
 */
export class CourseDetailsMapper {
    /**
     * Map a CatalogItem into a CourseDetails object.
     * @param catalogItem CatalogItem to map from.
     * @returns CourseDetails object
     */
    public static fromCatalogItem(catalogItem: CatalogItem): CourseDetails {
        return {
            catalogItemId: catalogItem.id,
            name: catalogItem.name,
            description: catalogItem.description,
            duration: this.fromCatalogItemDuration(catalogItem),
            category: catalogItem.domain.valueDisplayString,
            skillLevel: catalogItem.skillLevel.valueDisplayString,
            objectives: catalogItem.objectives.values,
            audience: catalogItem.intendedAudience.values,
            prerequisites: catalogItem.prerequisites.value,
            outline: { nodes: JSON.parse(catalogItem.outline) },
        };
    }

    /**
     * Convert the CatalogItem duration into a human-readable string.
     * https://code.amazon.com/packages/AwsTcCatalogService/blobs/a55f872d4e2c9e471ea6a20cbdf7ff06c5d7b8fa/--/src/api/schema.graphql#L1111
     * @param catalogItem Catalog Item that contains the duration.
     * @returns duration as a string or undefined if we cannot map the value.
     */
    public static fromCatalogItemDuration(catalogItem: CatalogItem): string | undefined {
        const { value, unit } = catalogItem.duration;
        switch (unit) {
            case 'HOURS':
                return `${value} ${this.pluralize(value, 'hour')}`;
            case 'MINUTES':
                return `${value} ${this.pluralize(value, 'minute')}`;
            case 'DAYS':
                return `${value} ${this.pluralize(value, 'day')}`;
            case 'SECONDS':
                return `${value} ${this.pluralize(value, 'second')}`;
            default:
                // TODO: Would like some logging / error handling here.
                return undefined;
        }
    }

    // Helper to pluralize units.
    private static pluralize(value: number, unit: string): string {
        return `${unit}` + (value > 1 ? 's' : '');
    }
}
