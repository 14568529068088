import { CourseOutline as CourseOutlineType, CourseOutlineNode, CourseProps } from '../../common/types';
import { Container, Header, SpaceBetween } from '@amzn/awsui-components-react';
import React from 'react';
import { createList } from '../../common/utils';
import './CourseOutline.scss';

export const CourseOutline = (props: { course: CourseProps }) => {
    const outlineNodes: CourseOutlineNode[] | undefined = formatOutline(props.course.courseDetails.outline);

    return (
        <SpaceBetween size='s'>
            {outlineNodes?.map((node: CourseOutlineNode, index: number) => (
                <SpaceBetween size='s' key={index} data-testid='course-outline-section'>
                    {node.header && <Header>{node.header}</Header>}
                    {!!node.details?.length && (
                        <Container>
                            <div>{createList(node.details)}</div>
                        </Container>
                    )}
                    <Container data-testid='course-outline-modules-container'>
                        {node.nodes?.map((moduleNode, index: number) => (
                            <div key={index} data-testid='course-outline-module'>
                                {moduleNode.header && <Header variant='h3'>{moduleNode.header}</Header>}
                                {/* Only render separator if there is content to separate. */}
                                {showSeparator(moduleNode) && !!moduleNode.details?.length && <hr className='module-separator' />}
                                {!!moduleNode.details?.length && <>{createList(moduleNode.details)}</>}
                            </div>
                        ))}
                    </Container>
                </SpaceBetween>
            ))}
        </SpaceBetween>
    );
};

/**
 * Some outlines have nesting to group modules by days and some do not.
 * To render the outline in a consistent manner, we need to check if the outline is nested.
 * If it's not nested, create an artificial nesting to render the modules within one container instead of separate containers.
 * @param outline
 * @returns course outline nodes array
 */
const formatOutline = (outline: CourseOutlineType): CourseOutlineNode[] | undefined => {
    if (!outline.nodes?.[0].nodes?.length) {
        return [{ nodes: outline.nodes }];
    }

    return outline.nodes;
};

/**
 * Helper method to determine if we should show a separator between the header and details.
 * @param node
 * @returns boolean
 */
const showSeparator = (node: CourseOutlineNode): boolean => {
    return !!node.header && !!node.details?.length;
};
