import React from 'react';
import { Box, SpaceBetween } from '@amzn/awsui-components-react';
import './DashboardItem.scss';
import { CourseProps } from '../../common';
import { DeliveryDetails, DurationIcon, LevelIcon, CategoryIcon } from '../../components';
import { NavLink } from 'react-router-dom';
import { CourseBadges } from '../course/Badges';

export const createCardDefinition = () => {
    return {
        header: (item: CourseProps) => (
            <SpaceBetween size='xs' direction='vertical'>
                <CourseBadges item={item} />
                <h2 className='title' aria-label='Class Title'>
                    <NavLink to={`/course/${item.id}`}>{item.courseDetails.name}</NavLink>
                </h2>
            </SpaceBetween>
        ),
        sections: [
            {
                id: 'duration',
                content: (item: CourseProps) => <DurationIcon value={item.courseDetails.duration} />,
                width: 25,
            },
            {
                id: 'skillLevel',
                content: (item: CourseProps) => <LevelIcon value={item.courseDetails.skillLevel} />,
                width: 35,
            },
            {
                id: 'category',
                content: (item: CourseProps) => <CategoryIcon value={item.courseDetails.category} />,
                width: 40,
            },
            {
                id: 'description',
                content: (item: CourseProps) => (
                    <Box>
                        <div className='description' aria-label='Description'>
                            {item.courseDetails.description}
                        </div>
                        <br />
                        <div className='location' aria-label='Location'>
                            <b>Location</b>: {item.location}
                        </div>
                        <br />
                        <DeliveryDetails course={item} />
                    </Box>
                ),
            },
        ],
    };
};
