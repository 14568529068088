import {
    ActivityModality,
    CatalogItem,
    ClassroomAssignments,
    CourseProps,
    Evaluation,
    EvaluationStatus,
    LearningActivitiesByUserIdResponse,
    LearningActivity,
    LmsType,
    Registration,
    RegistrationStatus,
    RegistrationType,
    Schedule,
    TrainingAssignmentStatus,
    Transcript,
    VIRTUAL_ILT_LOCATION,
    WaitlistEnabled,
} from './types';
import { CourseDetailsMapper } from '../mapper';
import { recordError } from '../clientMonitoring/rumClient';
import { FlagNames, isFeatureEnabled, Stages } from '@amzn/aws-tc-ilt-common';
import { getNodeEnvironment } from './nodeEnvironment';

function formatLocation(learningActivity: LearningActivity): string {
    if (learningActivity.activity_modality === ActivityModality.vILT) {
        return VIRTUAL_ILT_LOCATION;
    }

    const addressParts = [
        learningActivity.delivery_address_1,
        learningActivity.delivery_address_2,
        learningActivity.room,
        learningActivity.delivery_city,
        learningActivity.delivery_state,
        learningActivity.delivery_postal_code,
        learningActivity.delivery_country,
    ];

    return addressParts.filter(Boolean).join(', ');
}

export const convertLearningActivityToCourse = (learningActivity: LearningActivity): Omit<CourseProps, 'isProxy'> | undefined => {
    const catalogItem: CatalogItem | undefined = learningActivity.course_catalog_item;
    if (!catalogItem) {
        const error: Error = new Error(`No catalog item found for learning activity ${learningActivity.pk}`);
        recordError(error);
        return;
    }

    const schedule: Schedule = {
        classDays: learningActivity.delivery_sessions.map((deliverySession) => ({
            // TypeScript expects milliseconds, but the Grimsby timestamp is in seconds
            startDateTime: new Date(deliverySession.start_timestamp * 1_000),
            endDateTime: new Date(deliverySession.end_timestamp * 1_000),
        })),
        language: learningActivity.delivery_language,
        timezone: learningActivity.delivery_timezone,
        seatsRemaining: (learningActivity.class_size ?? 0) - learningActivity.registrationCounter,
    };

    return {
        id: learningActivity.pk,
        courseStatus: learningActivity.activity_status,
        classroomUrl: learningActivity.classrooms_student_url,
        meetingLink: learningActivity.v_ilt_id,
        schedule,
        courseDetails: CourseDetailsMapper.fromCatalogItem(catalogItem),
        activityModality: learningActivity.activity_modality,
        location: formatLocation(learningActivity),
        waitlistEnabled: learningActivity.waitlist_enabled || WaitlistEnabled.DISABLED,
    };
};

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export const convertLearningActivitiesToCourses = (learningActivitiesResult: LearningActivitiesByUserIdResponse): CourseProps[] => {
    const registrations: Registration[] = learningActivitiesResult.registrationsByUserId.nodes;
    const transcripts: Transcript[] = learningActivitiesResult.transcriptsByUserId.nodes;
    const evaluations: Evaluation[] = learningActivitiesResult.iltEvaluationsByUserId.nodes;

    const registeredLearningActivities = registrations.map((registration) => registration.learningActivity);
    const isClassroomAssignmentEnabled = isFeatureEnabled(FlagNames.CLASSROOM_TRAINING_ASSIGNMENT, getNodeEnvironment() as Stages);

    let aggregatedLearningActivities: LearningActivity[] = registeredLearningActivities;
    if (isClassroomAssignmentEnabled) {
        const assignments: ClassroomAssignments[] = learningActivitiesResult.classroomTrainingAssignmentsByUserId.nodes;
        const assignedLearningActivities = assignments.map((assignment) => assignment.learningActivity);
        aggregatedLearningActivities = aggregatedLearningActivities.concat(assignedLearningActivities);
    }

    return aggregatedLearningActivities
        .reduce((acc: LearningActivity[], current: LearningActivity) => {
            if (!acc.find((item: LearningActivity) => item.pk === current.pk)) {
                acc.push(current);
            }
            return acc;
        }, [])
        .filter((learningActivity) => learningActivity.lms_type === LmsType.myClass)
        .flatMap((learningActivity) => {
            const registration = registrations.find((reg) => reg.learningActivity.pk === learningActivity.pk);
            const registrationStatus = registration
                ? registration.registrationStatus === RegistrationStatus.UNWAITLISTED ||
                  registration.registrationStatus === RegistrationStatus.WITHDRAWN
                    ? RegistrationStatus.WITHDRAWN
                    : registration.registrationStatus
                : RegistrationStatus.WITHDRAWN;
            const isProxy = registration?.registrationType === RegistrationType.PROXY;
            const transcript = transcripts.find((transcript) => transcript.learningActivity.pk === learningActivity.pk);
            const evaluation = evaluations.find((evaluation) => evaluation.learningActivity.pk === learningActivity.pk);

            const courseProps: Omit<CourseProps, 'isProxy'> | undefined = convertLearningActivityToCourse(learningActivity);
            if (!courseProps) {
                const error: Error = new Error(`Unable to get course details from learning activity ${learningActivity.pk}`);
                recordError(error);
                return [];
            }

            return [
                {
                    ...courseProps,
                    registrationStatus: registrationStatus,
                    transcriptStatus: transcript?.transcriptStatus,
                    transcriptId: transcript?.id,
                    evaluationStatus: evaluation?.evaluationStatus ?? EvaluationStatus.NOT_REQUIRED,
                    evaluationUrl: evaluation?.evaluationUrl,
                    isProxy,
                },
            ];
        });
};
