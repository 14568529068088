import { gql, useQuery } from '@apollo/client';
import { recordError } from '../../clientMonitoring/rumClient';
import { useAuth } from '../auth/useAuth';
import { FlagNames, isFeatureEnabled, Stages } from '@amzn/aws-tc-ilt-common';
import { getNodeEnvironment } from '../../common';

const LEARNING_ACTIVITY_PARAMS = `
    pk
    activity_status
    activity_modality
    class_size
    classrooms_student_url
    course_name
    delivery_language
    delivery_sessions {
        start_timestamp
        end_timestamp
    }
    delivery_address_1
    delivery_address_2
    room
    delivery_city
    delivery_state
    delivery_postal_code
    delivery_country
    delivery_timezone
    lms_type
    registrationCounter
    v_ilt_id
    waitlist_enabled
    course_catalog_item {
        id
        versionedId
        name
        description
        domain {
            valueDisplayString
        }
        skillLevel {
            valueDisplayString
        }
        objectives {
            values
        }
        intendedAudience {
            values
        }
        prerequisites {
            value
        }
        duration {
            unit
            value
        }
        outline
    }
`;

const isClassroomAssignmentEnabled = isFeatureEnabled(FlagNames.CLASSROOM_TRAINING_ASSIGNMENT, getNodeEnvironment() as Stages);

// startTimestamp is set to 2020-01-01 & endTimeStamp to 2030-01-01 to fetch all classes
export const LEARNING_ACTIVITIES_BY_USER_ID = gql`
    query learningActivitiesByUserId($userId: ID!) {
        registrationsByUserId(userId: $userId, filter: { registrationModalityType: ILT }) {
            nodes {
                registrationStatus
                registrationType
                learningActivity {
                  ${LEARNING_ACTIVITY_PARAMS}
                }
            }
        }
        ${
            isClassroomAssignmentEnabled
                ? `
        classroomTrainingAssignmentsByUserId(userId: $userId, filter: {assignmentStatus: ASSIGNED}) {
            nodes {
                trainingAssignmentStatus
                learningActivity {
                  ${LEARNING_ACTIVITY_PARAMS}
                }
            }
        }`
                : ''
        }
      transcriptsByUserId(userId: $userId) {
            nodes {
                id
                learningActivity {
                    pk
                }
                transcriptStatus
            }
        }
        iltEvaluationsByUserId(userId: $userId) {
            nodes {
                evaluationUrl
                evaluationStatus
                learningActivity {
                    pk
                }
            }
        }
    }
`;

export const useLearningActivitiesByUserId = () => {
    const { user } = useAuth();
    return useQuery(LEARNING_ACTIVITIES_BY_USER_ID, {
        skip: !user.id,
        variables: { userId: user.id },
        notifyOnNetworkStatusChange: true,
        onError: (error) => {
            recordError(error);
        },
    });
};
