import React, {useContext, useState} from 'react';
import {Box, Button, SpaceBetween, StatusIndicator} from '@amzn/awsui-components-react';
import {ClassSchedule} from './ClassSchedule';
import './DeliveryDetails.scss';
import {daysUntilStart, getRemainingSeats, isInProgress} from '../../common/utils';
import {
    AttendanceStatus,
    ClassStatus,
    CourseProps,
    EvaluationStatus,
    LearningActivityStatus,
    RegistrationStatus
} from '../../common/types';
import {useRegisterLearningActivity, useWithdrawLearningActivity} from '../../hooks/registration';
import {NotificationContext} from '../notifications/NotificationContext';
import {CourseContext} from '../course/CourseContext';
import {getErrorMessageFromErrorCode} from '@amzn/aws-tc-ilt-common';
import {useLazyCertificate} from '../../hooks/certificate/useCertificate';

export interface DeliveryDetailsProps {
    course: CourseProps;
}

export const DeliveryDetails = (props: DeliveryDetailsProps) => {
    const {showInfoFlashbar, showSuccessFlashbar, showErrorFlashbar} = useContext(NotificationContext);
    const [callEnroll] = useRegisterLearningActivity(`${props.course.id}`);
    const [callWithdraw] = useWithdrawLearningActivity(`${props.course.id}`);
    const [getCertificate, {loading: loadingCertificate}] = useLazyCertificate();
    const [loading, setLoading] = useState(false);
    const {updateCourseStatus} = useContext(CourseContext);

    const downloadCertificate = () => {
        getCertificate({variables: {transcriptId: props.course.transcriptId}}).then((result) => {
            if (result.error) {
                showErrorFlashbar(getErrorMessageFromErrorCode(result.error.message));
            } else {
                const base64Pdf = `data:application/pdf;base64,${result.data.certificate}`;
                const fileName = 'Certificate.pdf';
                // Mock a download link to open the PDF in a new tab
                const mockDownloadLink = document.createElement('a');
                mockDownloadLink.href = base64Pdf;
                mockDownloadLink.download = fileName;
                mockDownloadLink.click();
            }
        });
    };

    const enroll = () => {
        setLoading(true);
        callEnroll().then((result) => {
            if (result.errors) {
                const errorMessage = (result.errors as unknown as {message: string}).message;
                showErrorFlashbar(getErrorMessageFromErrorCode(errorMessage));
            } else {
                updateCourseStatus(props.course.id, RegistrationStatus.REGISTERED);
                showSuccessFlashbar(
                    `Successfully registered for the class "${props.course.courseName}". The link to join the class will be available 15 minutes prior to the start time.`
                );
            }
            setLoading(false);
        });
    };

    const withdraw = () => {
        setLoading(true);
        callWithdraw().then((result) => {
            if (result.errors) {
                const errorMessage = (result.errors as unknown as {message: string}).message;
                showErrorFlashbar(getErrorMessageFromErrorCode(errorMessage));
            } else {
                updateCourseStatus(props.course.id, RegistrationStatus.WITHDRAWN);
                showInfoFlashbar(`Successfully withdrew from the class "${props.course.courseName}"`);
            }
            setLoading(false);
        });
    };

    const getClassStatus = (course: CourseProps) => {
        // Check if class is completed
        if (course.courseStatus === LearningActivityStatus.COMPLETED) {
            if (course.attendanceStatus === AttendanceStatus.NO_SHOW) {
                return ClassStatus.NO_SHOW;
            }
            if (course.attendanceStatus === AttendanceStatus.PARTIALLY_ATTENDED) {
                return ClassStatus.PARTIALLY_ATTENDED;
            }
            return ClassStatus.COMPLETED;
        }
        // Check if class is canceled
        if (course.courseStatus === LearningActivityStatus.CANCELED) {
            return ClassStatus.CANCELED;
        }
        // Class must be active. Check if learner is not already enrolled
        if (course.registrationStatus === RegistrationStatus.WITHDRAWN) {
            return ClassStatus.WITHDRAWN;
        }
        // Learner must be enrolled. Check if class is currently in progress
        if (course.schedule.classDays.some((day) => isInProgress(day.startDateTime))) {
            return ClassStatus.IN_PROGRESS;
        }
        // Learner must be enrolled and Class has not yet started
        return ClassStatus.ENROLLED;
    };

    const getStatusIndicator = (course: CourseProps) => {
        const classStatus = getClassStatus(course);
        switch (classStatus) {
            case ClassStatus.CANCELED:
                return (
                    <StatusIndicator type='error' data-testid='status-canceled'>
                        Canceled
                    </StatusIndicator>
                );
            case ClassStatus.NO_SHOW:
                return (
                    <StatusIndicator type='error' data-testid='status-no-show'>
                        No Show
                    </StatusIndicator>
                );
            case ClassStatus.PARTIALLY_ATTENDED:
                return (
                    <StatusIndicator type='info' data-testid='status-partially-attended'>
                        Partially Attended
                    </StatusIndicator>
                );
            case ClassStatus.COMPLETED:
                return <StatusIndicator data-testid='status-completed'>Completed</StatusIndicator>;
            case ClassStatus.IN_PROGRESS:
                return (
                    <StatusIndicator type='in-progress' data-testid='status-in-progress'>
                        In Progress
                    </StatusIndicator>
                );
            case ClassStatus.ENROLLED: {
                const days = daysUntilStart(course.schedule.classDays[0].startDateTime);
                return (
                    <StatusIndicator type='pending' data-testid='status-enrolled'>
                        Enrolled. {days === 1 ? `1 day left` : days > 1 ? `${days} days left` : ''}
                    </StatusIndicator>
                );
            }
            case ClassStatus.WITHDRAWN:
                return (
                    <StatusIndicator
                        type={props.course.schedule.seatsRemaining ? 'info' : 'stopped'}
                        data-testid='status-info'
                    >
                        {getRemainingSeats(props.course.schedule.seatsRemaining)}
                    </StatusIndicator>
                );
        }
    };

    const getButtons = (course: CourseProps) => {
        const classroomButton = course.classroomUrl && (
            <Button
                href={course.classroomUrl}
                iconAlign='right'
                iconName='external'
                target='_blank'
                ariaLabel='Builder Labs for classes'
                data-testid='button-aws-labs'
                className='classroom-link'
            >
                Builder Labs for classes
            </Button>
        );
        const nectoButton = (
            <Button
                variant='primary'
                href={course.nectoLink}
                iconAlign='right'
                iconName='external'
                target='_blank'
                ariaLabel='Join Training'
                data-testid='button-join-training'
            >
                Join Training
            </Button>
        );
        const enrollButton = (
            <Button
                variant='primary'
                onClick={() => enroll()}
                loading={loading}
                ariaLabel='Enroll'
                disabled={props.course.schedule.seatsRemaining <= 0}
                data-testid='button-enroll'
            >
                Enroll{!loading || 'ing'}
            </Button>
        );
        const withdrawButton = (
            <Button
                variant='link'
                onClick={() => withdraw()}
                ariaLabel='Withdraw'
                loading={loading}
                data-testid='button-withdraw'
            >
                Withdraw{!loading || 'ing'}
            </Button>
        );
        // Show evaluationButton only if status is incomplete
        const evaluationButton =
            course.evaluationUrl && course.evaluationStatus === EvaluationStatus.INCOMPLETE && (
                <Button
                    href={props.course.evaluationUrl}
                    iconAlign='right'
                    iconName='external'
                    ariaLabel='Evaluation Survey'
                    data-testid='button-evaluation'
                    target='_blank'
                >
                    Evaluation Survey
                </Button>
            );
        const certificateButton = (
            <Button
                onClick={() => downloadCertificate()}
                variant='primary'
                ariaLabel='Certificate'
                data-testid='button-certificate'
                loading={loadingCertificate}
            >
                {loadingCertificate ? 'Downloading' : 'Certificate'}
            </Button>
        );
        const classStatus = getClassStatus(course);
        switch (classStatus) {
            case ClassStatus.CANCELED:
                return null;
            case ClassStatus.NO_SHOW:
                return classroomButton;
            case ClassStatus.PARTIALLY_ATTENDED:
                return (
                    <SpaceBetween size='s' direction='horizontal'>
                        {classroomButton}
                        {evaluationButton}
                    </SpaceBetween>
                );
            case ClassStatus.COMPLETED:
                return (
                    <SpaceBetween size='s' direction='horizontal'>
                        {certificateButton}
                        {classroomButton}
                        {evaluationButton}
                    </SpaceBetween>
                );
            case ClassStatus.IN_PROGRESS:
                return (
                    <SpaceBetween size='s' direction='horizontal'>
                        {classroomButton}
                        {nectoButton}
                        {evaluationButton}
                    </SpaceBetween>
                );
            case ClassStatus.ENROLLED:
                return withdrawButton;
            case ClassStatus.WITHDRAWN:
                return enrollButton;
        }
    };

    return (
        <Box>
            <SpaceBetween size='xs' direction='horizontal'>
                <h4 className='header' aria-label='Class schedule'>
                    Class schedule
                </h4>
                {getStatusIndicator(props.course)}
            </SpaceBetween>
            <ClassSchedule schedule={props.course.schedule} registrationStatus={props.course.registrationStatus} />
            <Box className='buttons' float='right'>
                {getButtons(props.course)}
            </Box>
        </Box>
    );
};
