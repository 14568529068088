import { AWSCShortbread } from '@amzn/shortbread';
import { CookieCategory, CookieNames } from './types';
import { getNodeEnvironment, NodeEnvironment } from '../common/nodeEnvironment';
import { APPLICATION_URL } from '../common/constants';
import { rumInit } from '../clientMonitoring/rumClient';
import Cookies from 'js-cookie';

class CookieConsentManager {
    // Shortbread library usage https://w.amazon.com/bin/view/Shortbread/usage
    private shortBread: AWSCShortbread;
    private categoryMap: Map<string, CookieCategory> = new Map([
        ['essential', CookieCategory.Essential],
        ['performance', CookieCategory.Performance],
        ['functional', CookieCategory.Functional],
        ['advertising', CookieCategory.Advertising],
    ]);

    constructor() {
        this.shortBread = AWSCShortbread({
            language: 'en-US',
            parent: window.document.querySelector('#app') as HTMLElement,
            domain: this.getCookieDomain(),
            registry: {
                [CookieNames.CLOUDWATCH_RUM_S]: { category: 'functional' },
                [CookieNames.CLOUDWATCH_RUM_U]: { category: 'functional' },
            },
            onConsentChanged: () => {
                this.manageRUMCookies();
            },
        });
        if (this.hasCookieCategoryConsent(CookieCategory.Functional)) {
            rumInit(true);
        }
    }

    /**
     * Check for Cookie Consent using the Shortbread UI lib.
     * This is intended to be called once for every page, after the initial load.
     */
    public checkForCookieConsent = () => {
        this.shortBread.checkForCookieConsent();
    };

    /**
     * Customize using the Shortbread UI lib.
     * This is intended to be called when the user clicks on Cookie preferences in the footer.
     */
    public customizeCookies = () => {
        this.shortBread.customizeCookies();
    };

    /**
     * Returns whether a specific Cookie has consent.
     */
    public cookieHasConsent = (cookieName: string) => {
        return this.shortBread.hasConsent(cookieName);
    };

    /**
     * Returns whether a Cookie Category has consent.
     */
    public hasCookieCategoryConsent = (cookieCategory: CookieCategory) => {
        if (!this.isConsentCookieSet()) {
            return false;
        }
        return this.getConsentCookieList().includes(cookieCategory);
    };

    /**
     * Checks if consent cookie is set.
     */
    public isConsentCookieSet = () => {
        const consentCookie = this.shortBread.getConsentCookie();
        return !!consentCookie;
    };

    /**
     * Gets list of cookie categories that have consent.
     */
    private getConsentCookieList = () => {
        const consentCookie = this.shortBread.getConsentCookie();

        if (!consentCookie) {
            return [];
        }

        return Object.keys(consentCookie)
            .filter((category) => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                return consentCookie[category];
            })
            .map((c) => this.categoryMap.get(c));
    };

    /**
     * Places Cloudwatch RUM cookies if functional cookies are allowed and cookies are not present.
     * If functional cookies not permitted, and Cloudwatch Rum cookies present, removes those cookies.
     */
    private manageRUMCookies = (): void => {
        if (
            this.hasCookieCategoryConsent(CookieCategory.Functional) &&
            (!Cookies.get(CookieNames.CLOUDWATCH_RUM_S) || !Cookies.get(CookieNames.CLOUDWATCH_RUM_U))
        ) {
            rumInit(true);
        }

        if (
            !this.hasCookieCategoryConsent(CookieCategory.Functional) &&
            (Cookies.get(CookieNames.CLOUDWATCH_RUM_S) || Cookies.get(CookieNames.CLOUDWATCH_RUM_U))
        ) {
            Cookies.remove(CookieNames.CLOUDWATCH_RUM_S, { path: '/', domain: window.location.hostname });
            Cookies.remove(CookieNames.CLOUDWATCH_RUM_U, { path: '/', domain: window.location.hostname });
        }
    };

    /**
     * Determines the domain to set cookies on, based on the environment. Beta and Gamma are using
     * the same domain as previously setting cookies on Gamma would always override the cookies set on
     * Beta as Beta was a subdomain of Gamma.
     */
    private getCookieDomain = (): string => {
        const nodeEnvironment = getNodeEnvironment();
        switch (nodeEnvironment) {
            case NodeEnvironment.BETA:
            case NodeEnvironment.GAMMA:
                return new URL(APPLICATION_URL.GAMMA).hostname;
            case NodeEnvironment.PROD:
                return new URL(APPLICATION_URL.PROD).hostname;
            default:
                return new URL(APPLICATION_URL.DEVELOPMENT).hostname;
        }
    };
}

export default CookieConsentManager;
