import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import BreadcrumbGroup, { BreadcrumbGroupProps } from '@amzn/awsui-components-react/polaris/breadcrumb-group';
export function Breadcrumbs({ items }: { items: BreadcrumbGroupProps['items'] }) {
    const navigate = useNavigate();
    return (
        <BreadcrumbGroup
            items={[...items]}
            onFollow={(e) => {
                e.preventDefault();
                navigate(e.detail.href);
            }}
            ariaLabel='Breadcrumbs'
        />
    );
}
