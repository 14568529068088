import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Footer, NotificationProvider, PageHeader } from './components';
import { useAuth } from './hooks/auth/useAuth';
import { COURSE_DETAIL_ROUTE, DEFAULT_ROUTE, HOME_ROUTE, MATCH_ALL_ROUTE, PROFILE_ROUTE } from './common/routes';
import { AccessDenied, Course, Dashboard, PageNotFound } from './pages';
import { CourseProvider } from './components/course/CourseContext';
import { LoadingPage } from './pages/loadingPage/LoadingPage';
import Profile from './pages/profile/Profile';

const App = () => {
    const { loading, user, isAuthError } = useAuth();
    if (isAuthError) {
        return <AccessDenied />;
    }
    if (loading || !user.isLoggedIn) {
        return <LoadingPage text='Loading...' />;
    }
    return (
        <NotificationProvider>
            <CourseProvider>
                <BrowserRouter>
                    <PageHeader />
                    <Routes>
                        <Route path={DEFAULT_ROUTE} element={<Dashboard />} />
                        <Route path={HOME_ROUTE} element={<Dashboard />} />
                        <Route path={COURSE_DETAIL_ROUTE} element={<Course />} />
                        <Route path={PROFILE_ROUTE} element={<Profile />} />
                        <Route path={MATCH_ALL_ROUTE} element={<PageNotFound />} />
                    </Routes>
                    <Footer />
                </BrowserRouter>
            </CourseProvider>
        </NotificationProvider>
    );
};

export default App;
