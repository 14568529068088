import React, { useEffect } from 'react';
import { CourseProps, LearningActivityStatus, RegistrationStatus } from './types';
import { useSearchParams } from 'react-router-dom';
import { useSaveIltEvaluationResponse } from '../hooks/evaluation/useSaveIltEvaluationResponse';

export const getBreadcrumbItemsFromLocation = (location: string) => {
    return location.split('/').map((item) => {
        return { text: item.charAt(0).toUpperCase() + item.slice(1), href: location.split(item)[0] + item };
    });
};

export const getBreadcrumbItemsFromCourse = (title: string) => {
    return [
        { text: 'Home', href: '/' },
        { text: title, href: '#' },
    ];
};

export const getWeekDay = (date: Date) => {
    const WEEKDAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    return WEEKDAYS[date.getDay()];
};

export const daysUntilStart = (date: Date) => {
    const MILLISECONDS_PER_DAY = 86400000; // = 1000 * 60 * 60 * 24
    return Math.floor((date.getTime() - Date.now()) / MILLISECONDS_PER_DAY);
};

export const isInProgress = (date: Date): boolean => {
    const today = new Date();
    // Compare the year, month, and day of the two dates
    return date.getFullYear() === today.getFullYear() && date.getMonth() === today.getMonth() && date.getDate() === today.getDate();
};

export const getRemainingSeats = (seats: number) => {
    return seats === 0 ? 'No available seats' : `${seats} ${seats === 1 ? 'seat' : 'seats'} left`;
};

export const createList = (items?: string[]) => {
    if (!items || !items.length) {
        return null;
    }
    return (
        <ul>
            {items.map((item) => (
                <li key={item}>{item}</li>
            ))}
        </ul>
    );
};

export const availableCourses = (courses: CourseProps[]): CourseProps[] => {
    return courses.filter(
        (course) =>
            (course.registrationStatus === RegistrationStatus.WITHDRAWN || course.registrationStatus === RegistrationStatus.UNWAITLISTED) &&
            course.courseStatus === LearningActivityStatus.ACTIVE,
    );
};

export const waitlistedCourses = (courses: CourseProps[]): CourseProps[] => {
    return courses.filter(
        (course) => course.registrationStatus === RegistrationStatus.WAITLISTED && course.courseStatus === LearningActivityStatus.ACTIVE,
    );
};

export const enrolledCourses = (courses: CourseProps[]): CourseProps[] => {
    return courses.filter(
        (course) => course.registrationStatus === RegistrationStatus.REGISTERED && course.courseStatus === LearningActivityStatus.ACTIVE,
    );
};

export const pastCourses = (courses: CourseProps[]): CourseProps[] => {
    return courses.filter(
        (course) =>
            course.courseStatus != LearningActivityStatus.ACTIVE &&
            (course.registrationStatus === RegistrationStatus.REGISTERED || course.registrationStatus === RegistrationStatus.CANCELED),
    );
};

export const useSaveSurveyResponse = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const transcriptId = searchParams.get('transcriptid') ?? '';
    const responseId = searchParams.get('responseid') ?? '';
    const [saveResponse] = useSaveIltEvaluationResponse(transcriptId, responseId);
    useEffect(() => {
        if (transcriptId && responseId) {
            saveResponse().then(() => setSearchParams());
        }
    }, [responseId, transcriptId]);
};
