import { Box, Cards, Tabs } from '@amzn/awsui-components-react';
import React, { useContext, useEffect, useState } from 'react';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { createCardDefinition } from './DashboardItem';
import { CourseProps, LearningActivityStatus, RegistrationStatus } from '../../common';
import styled from 'styled-components';
import { availableCourses, enrolledCourses, pastCourses } from '../../common';
import { CourseContext } from '../../components/course/CourseContext';

const StyledTabs = styled(Tabs)`
    h2 {
        font-size: x-large;
        text-align: center;
        width: 100%;
        margin: -30px 0 0 0;
    }

    @media screen and (max-width: 768px) {
        h2 {
            font-size: 16px;
        }
    }

    h2 p {
        font-size: 48px;
        font-weight: 900;
        margin: 30px 0 0 0;
    }

    li[role='presentation'] {
        width: 33%;
        height: 120px;
    }

    li[role='presentation'] div {
        width: 100%;
    }

    li[role='presentation'] button {
        width: 100%;
    }

    li[role='presentation'] button span {
        width: 100%;
    }

    div[role='tabpanel'] {
        display: none !important;
    }
`;

const enum Tab {
    Available = 'Available',
    Enrolled = 'Enrolled',
    Past = 'Past',
}

export const DashboardList: React.FC = () => {
    const { loading, courses } = useContext(CourseContext);
    const [activeTab, setActiveTab] = useState<Tab>(Tab.Available);

    const { items, collectionProps } = useCollection(courses, {
        filtering: {
            filteringFunction: (item: CourseProps) => {
                if (activeTab === Tab.Past) {
                    return (
                        item.courseStatus != LearningActivityStatus.ACTIVE &&
                        (item.registrationStatus === RegistrationStatus.REGISTERED ||
                            item.registrationStatus === RegistrationStatus.CANCELED)
                    );
                }
                const statusFilter = activeTab === Tab.Available ? RegistrationStatus.WITHDRAWN : RegistrationStatus.REGISTERED;
                return item.registrationStatus === statusFilter && item.courseStatus === LearningActivityStatus.ACTIVE;
            },
        },
    });

    useEffect(() => {
        // Whenever the learner registers or withdraws, check if we should switch tabs because the current tab might be empty.
        if (!courses.length) return;
        let currentTab = activeTab;
        // If the active tab is available classes but there are none, then set it to enrolled classes instead
        if (currentTab === Tab.Available && availableCourses(courses).length === 0) {
            currentTab = Tab.Enrolled;
        }
        // If active tab is enrolled classes but there are none, then set it to available classes unless there are none, in which case set it to past.
        if (currentTab === Tab.Enrolled && enrolledCourses(courses).length === 0) {
            currentTab = availableCourses(courses).length > 0 ? Tab.Available : Tab.Past;
        }
        setActiveTab(currentTab);
    }, [courses]);

    return (
        <Cards
            {...collectionProps}
            cardDefinition={createCardDefinition()}
            cardsPerRow={[
                { cards: 1 },
                {
                    minWidth: 768,
                    cards: 2,
                },
            ]}
            loading={loading}
            loadingText='Loading classes'
            empty={
                <Box margin={{ vertical: 'xs' }} textAlign='center' color='inherit'>
                    <b>No classes found</b>
                </Box>
            }
            items={items}
            filter={
                <StyledTabs
                    tabs={[
                        {
                            id: Tab.Available,
                            label: (
                                <h2>
                                    Available classes
                                    <p>{availableCourses(courses).length}</p>
                                </h2>
                            ),
                            disabled: availableCourses(courses).length === 0,
                        },
                        {
                            id: Tab.Enrolled,
                            label: (
                                <h2>
                                    Enrolled classes
                                    <p>{enrolledCourses(courses).length}</p>
                                </h2>
                            ),
                            disabled: enrolledCourses(courses).length === 0,
                        },
                        {
                            id: Tab.Past,
                            label: (
                                <h2>
                                    Past classes
                                    <p>{pastCourses(courses).length}</p>
                                </h2>
                            ),
                            disabled: pastCourses(courses).length === 0,
                        },
                    ]}
                    onChange={({ detail }) => {
                        setActiveTab(detail.activeTabId as Tab);
                    }}
                    activeTabId={activeTab}
                />
            }
        />
    );
};
