import React from 'react';
import './Footer.scss';
import { Box, SpaceBetween, TextContent } from '@amzn/awsui-components-react';
import { PRIVACY_PAGE_LINK, TERMS_PAGE_LINK } from '../../common/constants';
import CookieConsentManager from '../../cookies/CookieConsentManager';

export const Footer: React.FC = () => {
    const currentYear = new Date().getFullYear().toString();
    const cookieConsentMananger = new CookieConsentManager();
    return (
        <div className='footer-wrapper' id='footer'>
            <footer className='footer'>
                <nav>
                    <TextContent className='right-align'>
                        <SpaceBetween direction='horizontal' size='l'>
                            <div className='footer__actions'>© {currentYear}, Amazon Web Services, Inc. or its affiliates</div>
                            <div className='footer__actions'>
                                <Box>
                                    <button
                                        className='link-button'
                                        data-testid='privacy_link'
                                        onClick={() => {
                                            window.open(PRIVACY_PAGE_LINK);
                                        }}
                                    >
                                        Privacy
                                    </button>
                                </Box>
                            </div>
                            <div className='footer__actions'>
                                <Box>
                                    <button
                                        className='link-button'
                                        data-testid='terms_link'
                                        onClick={() => {
                                            window.open(TERMS_PAGE_LINK);
                                        }}
                                    >
                                        Terms
                                    </button>
                                </Box>
                            </div>
                            <div className='footer__actions'>
                                <Box>
                                    <button
                                        className='link-button'
                                        data-testid='cookie_preferences'
                                        onClick={cookieConsentMananger.customizeCookies}
                                    >
                                        Cookie preferences
                                    </button>
                                </Box>
                            </div>
                        </SpaceBetween>
                    </TextContent>
                </nav>
            </footer>
        </div>
    );
};
