import { AppLayout, ContentLayout, Flashbar, Header } from '@amzn/awsui-components-react';
import React, { useContext } from 'react';
import { DashboardListWithWaitlistedTab } from './DashboardListWithWaitlistedTab';
import { DashboardList } from './DashboardList';
import { APPLICATION_NAME, getBreadcrumbItemsFromLocation, getNodeEnvironment, useSaveSurveyResponse } from '../../common';
import { Breadcrumbs, Navigation, NotificationContext } from '../../components';
import { FlagNames, isFeatureEnabled, Stages } from '@amzn/aws-tc-ilt-common';

const content = () => {
    document.title = APPLICATION_NAME;
    const isWaitlistEnabled = isFeatureEnabled(FlagNames.WAITLIST_FOR_FRONTEND, getNodeEnvironment() as Stages);
    return (
        <ContentLayout
            header={
                <Header
                    variant='h1'
                    description='You can view the list of all classes available in your dashboard,
                    and once you have enrolled in the class, you will be able to join it when the class begins.'
                >
                    My Dashboard
                </Header>
            }
        >
            {isWaitlistEnabled ? <DashboardListWithWaitlistedTab /> : <DashboardList />}
        </ContentLayout>
    );
};

export const Dashboard = () => {
    const { notifications } = useContext(NotificationContext);
    useSaveSurveyResponse();
    return (
        <AppLayout
            content={content()}
            navigation={<Navigation />}
            contentType={'wizard'}
            notifications={<Flashbar items={notifications} />}
            toolsHide={true}
            breadcrumbs={<Breadcrumbs items={getBreadcrumbItemsFromLocation('home')} />}
        />
    );
};
