import { SpaceBetween } from '@amzn/awsui-components-react';
import { ClassTypeBadge } from './ClassTypeBadge';
import { ProxyRegisteredBadge } from './ProxyRegisteredBadge';
import React from 'react';

export function CourseBadges({ item }: { item: { activityModality: string; isProxy: boolean } }) {
    return (
        <SpaceBetween size='xs' direction='horizontal'>
            <ClassTypeBadge item={item} />
            {item.isProxy ? <ProxyRegisteredBadge /> : <></>}
        </SpaceBetween>
    );
}
