import React from 'react';
import { Badge } from '@amzn/awsui-components-react';
import { ActivityModality } from '../../common';

export function ClassTypeBadge({ item }: { item: { activityModality: string } }) {
    return (
        <Badge color={item.activityModality === ActivityModality.vILT ? 'green' : 'blue'}>
            <div aria-label='Class Type'>{item.activityModality === ActivityModality.vILT ? 'Virtual Classroom' : 'Classroom'}</div>
        </Badge>
    );
}
