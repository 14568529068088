import {NodeEnvironment, getNodeEnvironment} from './nodeEnvironment';
export const APPLICATION_NAME = 'myClass';
export const PRIVACY_PAGE_LINK = 'https://aws.amazon.com/privacy';
export const TERMS_PAGE_LINK = 'https://aws.amazon.com/terms';
export const CERTIFICATION_PAGE = 'https://www.aws.training/SignIn?returnUrl=%2FCertification';
export const CLIENT_ID = '136u1ukf9ea43hpqqbo05v7es1';
export const LOGOUT_URL = `https%3A%2F%2Fus-east-1.auth.skillbuilder.aws%2Flogout%3Fclient_id%3D${CLIENT_ID}%26logout_uri%3Dhttps%3A%2F%2Flogin.us-east-1.auth.skillbuilder.aws%2Flogout%3Fclient_id%3D${CLIENT_ID}`;
export const EMAIL_VERIFICATION_ERROR = 'EmailAddress is not verified, verification required';

export enum APPLICATION_URL {
    DEVELOPMENT = 'http://localhost:3000',
    BETA = 'https://beta.myclass.skillbuilder.training.aws.dev',
    GAMMA = 'https://myclass.skillbuilder.training.aws.dev',
    PROD = 'https://myclass.skillbuilder.aws'
}

const endpoints = {
    // Point dev to local fastify server and others to beta supergraph
    [NodeEnvironment.TEST]: `${APPLICATION_URL.DEVELOPMENT}/graphql`,
    [NodeEnvironment.DEVELOPMENT]: `${APPLICATION_URL.DEVELOPMENT}/graphql`,
    [NodeEnvironment.BETA]: `${APPLICATION_URL.BETA}/graphql`,
    [NodeEnvironment.GAMMA]: `${APPLICATION_URL.GAMMA}/graphql`,
    [NodeEnvironment.PROD]: `${APPLICATION_URL.PROD}/graphql`
};

export const getClientEndpoint = () => {
    return endpoints[getNodeEnvironment()] || endpoints[NodeEnvironment.TEST];
};

export enum RoleType {
    SERVICE = 'SERVICE',
    SUPER_USER = 'SUPER_USER',
    INTERNAL_EMPLOYEE = 'INTERNAL_EMPLOYEE',
    LEARNING_ADMIN = 'LEARNING_ADMIN',
    POST_AUTH_PRE_VIAS = 'POST_AUTH_PRE_VIAS',
    LEARNING_ACCOUNT_IT_CONTACT = 'LEARNING_ACCOUNT_IT_CONTACT',
    LEARNER = 'LEARNER'
}

export enum AuthEndpoint {
    SIGN_IN = 'signIn',
    SIGN_OUT = 'signOut',
    REFRESH_TOKEN = 'refreshToken'
}
