import {gql, useMutation} from '@apollo/client';
import {useAuth} from '../auth/useAuth';
import {recordError} from '../../clientMonitoring/rumClient';

export const WITHDRAW_LEARNING_ACTIVITY = gql`
    mutation withdrawLearningActivity($userId: ID!, $learningActivityId: ID!) {
        withdrawLearningActivity(userId: $userId, learningActivityId: $learningActivityId) {
            id
            registrationStatus
        }
    }
`;

export const useWithdrawLearningActivity = (learningActivityId: string) => {
    const {user} = useAuth();
    return useMutation(WITHDRAW_LEARNING_ACTIVITY, {
        variables: {userId: user.id, learningActivityId: learningActivityId},
        onError: (error) => {
            recordError(error);
        }
    });
};
