import React from 'react';
import TopNavigation, {TopNavigationProps} from '@amzn/awsui-components-react/polaris/top-navigation';
import './PageHeader.scss';
import {ApplicationUser, useAuth} from '../../hooks/auth/useAuth';
import {HOME_ROUTE, SIGN_OUT_ROUTE} from '../../common/routes';
import {APPLICATION_NAME} from '../../common/constants';
import {useNavigate} from 'react-router-dom';

const generateHeaderUtilities = (currentUser: ApplicationUser): TopNavigationProps.Utility[] => {
    // Hide the user name and sign out button dropdown if user has already logged out, otherwise render it
    return currentUser.id
        ? [
              {
                  type: 'menu-dropdown',
                  text: `${currentUser.firstName} ${currentUser.lastName}`,
                  description: `${currentUser.email}`,
                  iconName: 'user-profile',
                  items: [{id: 'signout', text: 'Sign out', href: SIGN_OUT_ROUTE}],
                  ariaLabel: 'Profile'
              }
          ]
        : [];
};

export const PageHeader: React.FC = () => {
    const {user} = useAuth();
    const navigate = useNavigate();

    return (
        <TopNavigation
            identity={{
                href: '/',
                title: APPLICATION_NAME,
                onFollow: (e: {preventDefault: () => void}) => {
                    e.preventDefault();
                    navigate(HOME_ROUTE);
                },
                logo: {
                    src: '/public/aws-tc-logo.svg',
                    alt: 'AWS Training and Certification logo'
                }
            }}
            utilities={generateHeaderUtilities(user)}
            i18nStrings={{
                searchIconAriaLabel: 'Search',
                searchDismissIconAriaLabel: 'Close search',
                overflowMenuTriggerText: 'More',
                overflowMenuTitleText: 'All',
                overflowMenuBackIconAriaLabel: 'Back',
                overflowMenuDismissIconAriaLabel: 'Close menu'
            }}
        />
    );
};
