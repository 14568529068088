import {gql, useLazyQuery, useQuery} from '@apollo/client';
import {recordError} from '../../clientMonitoring/rumClient';

export const CERTIFICATE = gql`
    query certificate($transcriptId: ID!) {
        certificate(transcriptId: $transcriptId)
    }
`;

export const useLazyCertificate = () => {
    return useLazyQuery(CERTIFICATE, {
        onError: (error) => {
            recordError(error);
        }
    });
};
