export interface CourseProps {
    id: string;
    courseStatus: LearningActivityStatus;
    schedule: Schedule;
    registrationStatus?: RegistrationStatus;
    transcriptStatus?: TranscriptStatus;
    transcriptId?: string;
    classroomUrl?: string;
    meetingLink?: string;
    evaluationStatus?: EvaluationStatus;
    evaluationUrl?: string;
    courseDetails: CourseDetails;
    activityModality: string;
    location: string;
    waitlistEnabled?: WaitlistEnabled;
    isProxy: boolean;
}

/**
 * This interface is for course details that are not tied to a specific session.
 * This is data like course name, description etc.
 * Other data like schedule, classroomURL should not be placed here.
 */
export interface CourseDetails {
    catalogItemId: string; // Unversioned ID from CatalogService
    name: string; // Name of the course e.g. Architecting on AWS
    description: string; // Description of the course.
    duration?: string; // Formatted string for duration of the course e.g. 3 Days
    category: string; // Category of the course e.g. "Media Services" -- this is represented as CatalogItem.domain
    skillLevel: string; // Skill level of a course e.g. Intermediate
    objectives: string[]; // Learner objectives
    audience: string[]; // Who this course is intended for
    outline: CourseOutline; // Outline of the course e.g. Day 1 you will learn X, Y, Z
    prerequisites: string; // Prerequisites, this is just a single string due to CatalogItem schema.
}

export interface Registration {
    registrationStatus: RegistrationStatus;
    learningActivity: LearningActivity;
    registrationType: RegistrationType;
}

export interface ClassroomAssignments {
    trainingAssignmentStatus: TrainingAssignmentStatus;
    learningActivity: LearningActivity;
}

export interface Transcript {
    id: string;
    learningActivity: LearningActivity;
    transcriptStatus: TranscriptStatus;
}

export interface Evaluation {
    evaluationUrl?: string;
    evaluationStatus: EvaluationStatus;
    learningActivity: LearningActivity;
}

export interface LearningActivity {
    pk: string;
    activity_status: LearningActivityStatus;
    activity_modality: string;
    class_size?: number;
    classrooms_student_url: string;
    course_name: string;
    delivery_language: string;
    delivery_sessions: DeliverySession[];
    delivery_address_1?: string;
    delivery_address_2?: string;
    room?: string;
    delivery_city?: string;
    delivery_state?: string;
    delivery_postal_code?: string;
    delivery_country?: string;
    delivery_timezone: string;
    lms_type?: LmsType;
    registrationCounter: number;
    v_ilt_id: string;
    waitlist_enabled?: WaitlistEnabled;
    course_catalog_item?: CatalogItem;
}

export interface DeliverySession {
    start_timestamp: number;
    end_timestamp: number;
}

export enum LearningActivityStatus {
    ACTIVE = 'Active',
    CANCELED = 'Canceled',
    COMPLETED = 'Completed',
}

export enum RegistrationStatus {
    CANCELED = 'CANCELED',
    REGISTERED = 'REGISTERED',
    WITHDRAWN = 'WITHDRAWN',
    WAITLISTED = 'WAITLISTED',
    UNWAITLISTED = 'UNWAITLISTED',
}

export enum RegistrationType {
    SELF = 'SELF',
    PROXY = 'PROXY',
}

export enum WaitlistEnabled {
    DISABLED = 'Disabled',
    AUTOMATIC = 'Automatic',
    MANUAL = 'Manual',
}

export enum TrainingAssignmentStatus {
    ASSIGNED = 'ASSIGNED',
    UNASSIGNED = 'UNASSIGNED',
}

export enum TranscriptStatus {
    ATTENDED = 'ATTENDED',
    NO_SHOW = 'NO_SHOW',
    PARTIALLY_ATTENDED = 'PARTIALLY_ATTENDED',
}

export enum ClassStatus {
    CANCELED,
    COMPLETED,
    WAITLISTED,
    ENROLLED,
    IN_PROGRESS,
    NO_SHOW,
    PARTIALLY_ATTENDED,
    WITHDRAWN,
}

export enum LmsType {
    Kiku = 'Kiku',
    myClass = 'myClass',
    CustomerLMS = 'CustomerLMS',
}

export enum ActivityModality {
    vILT = 'vILT',
    ILT = 'ILT',
}

export enum EvaluationStatus {
    NOT_REQUIRED = 'NOT_REQUIRED',
    INCOMPLETE = 'INCOMPLETE',
    COMPLETED = 'COMPLETED',
}

export const IsProxy = {
    TRUE: true,
    FALSE: false,
};

export const VIRTUAL_ILT_LOCATION = 'Virtual Live Class';

export interface Schedule {
    classDays: ClassDay[];
    timezone: string;
    language: string;
    seatsRemaining: number;
}

export interface ClassDay {
    startDateTime: Date;
    endDateTime: Date;
}

export enum SkillLevel {
    Fundamental = 'Fundamental',
    Intermediate = 'Intermediate',
    Advanced = 'Advanced',
}

export interface Module {
    title: string;
    modulesContent: {
        header: string;
        content: string[];
    }[];
}

export type IconSize = 'small' | 'large';

export type IconProps = {
    value?: string;
    size?: IconSize;
};

// Supergraph type for CatalogItem
// https://code.amazon.com/packages/AwsTcCatalogService/blobs/mainline/--/src/api/schema.graphql
export interface CatalogItem {
    id: string;
    versionedId: string;
    name: string;
    description: string;
    duration: {
        unit: string;
        value: number;
    };
    domain: {
        valueDisplayString: string;
    };
    skillLevel: {
        valueDisplayString: string;
    };
    objectives: {
        values: string[];
    };
    intendedAudience: {
        values: string[];
    };
    prerequisites: {
        value: string;
    };
    outline: string; // CatalogService expect this outline to be a stringified JSON Array, cannot be JSON Object
}

/**
 * The outline structure for courses. Basically an unbounded tree.
 * For now, our course UI will only display 1 level of module nesting.
 * So even if this has n-many depths, we will only show the first level (for now).
 */
export interface CourseOutline {
    nodes?: CourseOutlineNode[];
}

export interface CourseOutlineNode {
    header?: string; // The header of the node. This is the title of the node.
    details?: string[]; // List of details under a specific node.
    nodes?: CourseOutlineNode[]; // Additional nodes/nesting for more organization
}

// Response type for the GraphQL Query in /useLearningActivitiesByUserId.ts
export interface LearningActivitiesByUserIdResponse {
    learningActivities: LearningActivity[];
    registrationsByUserId: {
        nodes: Registration[];
    };
    classroomTrainingAssignmentsByUserId: {
        nodes: ClassroomAssignments[];
    };
    transcriptsByUserId: {
        nodes: Transcript[];
    };
    iltEvaluationsByUserId: {
        nodes: Evaluation[];
    };
}
