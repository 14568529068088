import {APPLICATION_URL, RoleType, AuthEndpoint, EMAIL_VERIFICATION_ERROR} from '../../common/constants';
import {useEffect, useState} from 'react';
import {useGetCurrentUser} from '../user/getCurrentUser';
import {getNodeEnvironment} from '../../common/nodeEnvironment';

export interface ApplicationUserInitProps {
    id?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    isLoggedIn?: boolean;
    role?: RoleType;
}

export interface AuthResponse {
    user: ApplicationUser;
    isAuthError?: boolean;
    authErrorMessage?: string;
    loading: boolean;
}

export class ApplicationUser {
    readonly id?: string;
    readonly firstName?: string;
    readonly lastName?: string;
    readonly email?: string;
    readonly isLoggedIn?: boolean;
    readonly role?: RoleType;

    constructor(initProps: ApplicationUserInitProps) {
        this.id = initProps.id;
        this.firstName = initProps.firstName;
        this.lastName = initProps.lastName;
        this.email = initProps.email;
        this.isLoggedIn = initProps.isLoggedIn;
        this.role = initProps.role;
    }
}

const addParamsAndCreateFullURL = (endpoint: AuthEndpoint, searchParams: Record<string, string> = {}) => {
    const nodeEnvironment = getNodeEnvironment();
    let currentDomain;
    switch (nodeEnvironment) {
        case 'beta':
            currentDomain = APPLICATION_URL.BETA;
            break;
        case 'gamma':
            currentDomain = APPLICATION_URL.GAMMA;
            break;
        case 'prod':
            currentDomain = APPLICATION_URL.PROD;
            break;
        default:
            currentDomain = 'http://localhost:3000';
            break;
    }
    const urlWithPath = new URL(`${currentDomain}/${endpoint}`);
    Object.entries(searchParams).forEach(([key, value]) => {
        urlWithPath.searchParams.set(key, value);
    });
    urlWithPath.searchParams.set('redirect_uri', searchParams.redirect_uri ?? currentDomain);

    return urlWithPath.toString();
};

const getSignInURL = (searchParams: Record<string, string> = {}) => {
    return addParamsAndCreateFullURL(AuthEndpoint.SIGN_IN, searchParams);
};

export const refreshToken = async (): Promise<Response> => {
    const refreshTokenURL = addParamsAndCreateFullURL(AuthEndpoint.REFRESH_TOKEN);
    return fetch(refreshTokenURL, {method: 'POST'});
};

export const useAuth = (): AuthResponse => {
    const [id, setId] = useState<string | undefined>(undefined);
    const [firstName, setFirstName] = useState<string | undefined>(undefined);
    const [lastName, setLastName] = useState<string | undefined>(undefined);
    const [signedInUserEmail, setSignedInUserEmail] = useState<string | undefined>(undefined);
    const [isLoggedIn, setIsLoggedIn] = useState<boolean | undefined>(undefined);
    const [userRole, setUserRole] = useState<RoleType | undefined>(undefined);
    const [authError, setAuthError] = useState<string | undefined>(undefined);

    const {data, error, loading} = useGetCurrentUser();

    useEffect(() => {
        if (error) {
            setIsLoggedIn(false);
            // If the user does not have an existing account, UserService will throw an error unless the email is verified.
            // Thus we redirect back to Gandalf and request email verification (https://t.corp.amazon.com/V1201101843)
            if (error.message.includes(EMAIL_VERIFICATION_ERROR)) {
                window.location.href = addParamsAndCreateFullURL(AuthEndpoint.SIGN_OUT, {
                    redirect_uri: getSignInURL({require_email_verification: 'true'})
                });
            } else {
                // To clear all expired cookies, call /signOut first and then redirect to /signIn
                window.location.href = addParamsAndCreateFullURL(AuthEndpoint.SIGN_IN);
            }
        }

        if (data) {
            refreshToken().then(() => {
                setId(data.currentUser.id);
                setFirstName(data.currentUser.firstName);
                setLastName(data.currentUser.lastName);
                setSignedInUserEmail(data.currentUser.emailAddress);
                setIsLoggedIn(true);
                setUserRole(data.currentUser.userRole);
            });
        }
    }, [data, error]);

    return {
        user: new ApplicationUser({
            id,
            firstName,
            lastName,
            email: signedInUserEmail,
            isLoggedIn: isLoggedIn,
            role: userRole
        }),
        authErrorMessage: authError,
        isAuthError: !!authError,
        loading
    };
};
