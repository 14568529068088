import { AppLayout, Button, Container, ContentLayout, FormField, Header, Input, SpaceBetween } from '@amzn/awsui-components-react';
import React, { useContext, useEffect, useState } from 'react';
import { Navigation } from '../../components';
import { getBreadcrumbItemsFromCourse, pastCourses } from '../../common';
import { useAuth } from '../../hooks/auth/useAuth';
import { Breadcrumbs } from '../../components';
import { CourseContext } from '../../components/course/CourseContext';

export const Profile = () => {
    const { user } = useAuth();
    const { loading, courses } = useContext(CourseContext);
    const [csv, setCsv] = useState('');
    useEffect(() => {
        const rows = pastCourses(courses).map((course) => [
            course.courseDetails.name,
            course.transcriptStatus,
            course.schedule.classDays[0].startDateTime,
            course.schedule.classDays[course.schedule.classDays.length - 1].endDateTime,
            course.courseDetails.duration,
            course.schedule.language,
            course.courseDetails.category,
            course.courseDetails.skillLevel,
        ]);
        const header = 'data:text/csv;charset=utf-8,Course, Status, Start Date, End Date, Duration, Language, Category, Level\r\n';
        setCsv(encodeURI(header + rows.map((row) => row.join(',')).join('\r\n')));
    }, [courses]);

    const content = () => {
        return (
            <ContentLayout
                header={
                    <Header variant='h2' description='Personal Information'>
                        Profile
                    </Header>
                }
            >
                <Container>
                    <SpaceBetween size='s'>
                        <FormField label='First Name'>
                            <Input value={user.firstName ?? ''} disabled={true} />
                        </FormField>
                        <FormField label='Last Name'>
                            <Input value={user.lastName ?? ''} disabled={true} />
                        </FormField>
                        <FormField label='Email'>
                            <Input value={user.email ?? ''} disabled={true} />
                        </FormField>
                        <Button iconName='download' download={'Transcript.csv'} href={csv} loading={loading}>
                            Download Transcript
                        </Button>
                    </SpaceBetween>
                </Container>
            </ContentLayout>
        );
    };

    return (
        <AppLayout
            content={content()}
            navigation={<Navigation />}
            toolsHide={true}
            breadcrumbs={<Breadcrumbs items={getBreadcrumbItemsFromCourse('Profile')} />}
        />
    );
};

export default Profile;
