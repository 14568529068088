import {SkillLevel} from './types';

export const getCourseDetailsByCourseName = (courseName: string) => {
    switch (courseName) {
        case 'AWS Cloud Practitioner Essentials':
            return cloudPractitionerDetails;
        default:
            return architectingOnAwsDetails;
    }
};

const cloudPractitionerDetails = {
    description:
        'This course is for individuals who seek an overall understanding of the Amazon Web Services (AWS) Cloud, ' +
        'independent of specific technical roles. You will learn about AWS Cloud concepts, AWS services, security, ' +
        'architecture, pricing, and support to build your AWS Cloud knowledge. This course also helps you prepare for ' +
        'the AWS Certified Cloud Practitioner exam.',
    duration: '1 day',
    category: 'Cloud Services',
    skillLevel: SkillLevel.Fundamental,
    objectives: {
        header: 'In this course, you will learn to:',
        contents: [
            'Summarize the working definition of AWS',
            'Differentiate between on-premises, hybrid-cloud, and all-in cloud',
            'Describe the basic global infrastructure of the AWS Cloud',
            'Explain the six benefits of the AWS Cloud',
            'Describe and provide an example of the core AWS services, including compute, network, databases, and storage',
            'Identify an appropriate solution using AWS Cloud services with various use cases',
            'Describe the AWS Well-Architected Framework',
            'Explain the shared responsibility model',
            'Describe the core security services within the AWS Cloud',
            'Describe the basics of AWS Cloud migration',
            'Articulate the financial benefits of the AWS Cloud for an organization’s cost management',
            'Define the core billing, account management, and pricing models',
            'Explain how to use pricing tools to make cost-effective choices for AWS services'
        ]
    },
    audience: {
        header: 'This course is intended for:',
        contents: [
            'Sales', 'Legal', 'Marketing', 'Business analysts and Project managers'
        ]
    },
    activities:
        'This course includes presentations based on use cases. It also includes group discussions, demonstrations, assessments, and hands-on labs. ',
    prerequisites: {
        languages: ['Java'],
        cloud: ['AWS Technical Essentials'],
        skillCenter: ['Completed AWS Technical Essentials '],
        general: ['General IT business knowledge', 'General IT technical knowledge']
    },
    modules: [
        {
            title: 'Day 1',
            modulesContent: [
                {
                    header: 'Module 1: Introduction to Amazon Web Services',
                    content: [
                        'Summarize the benefits of AWS',
                        'Describe differences between on-demand delivery and cloud deployments',
                        'Summarize the pay-as-you-go pricing model'
                    ]
                },
                {
                    header: 'Module 2: Compute in the Cloud',
                    content: [
                        'Describe the benefits of Amazon Elastic Compute Cloud (Amazon EC2) at a basic level',
                        'Identify the different Amazon EC2 instance types',
                        'Differentiate between the various billing options for Amazon EC2',
                        'Describe the benefits of Amazon EC2 Auto Scaling',
                        'Summarize the benefits of Elastic Load Balancing',
                        'Give an example of the uses for Elastic Load Balancing',
                        'Summarize the differences between Amazon Simple Notification Service (Amazon SNS) and Amazon Simple Queue Services (Amazon SQS)',
                        'Summarize additional AWS compute options '
                    ]
                },
                {
                    header: 'Module 3: Global Infrastructure and Reliability',
                    content: [
                        'Summarize the benefits of the AWS Global Infrastructure',
                        'Describe the basic concept of Availability Zones',
                        'Describe the benefits of Amazon CloudFront and Edge locations',
                        'Compare different methods for provisioning AWS services'
                    ]
                },
                {
                    header: 'Module 4: Networking',
                    content: [
                        'Describe the basic concepts of networking',
                        'Describe the difference between public and private networking resources',
                        'Explain a virtual private gateway using a real life scenario',
                        'Explain a virtual private network (VPN) using a real life scenario',
                        'Describe the benefit of AWS Direct Connect',
                        'Describe the benefit of hybrid deployments',
                        'Describe the layers of security used in an IT strategy',
                        'Describe which services are used to interact with the AWS global network'
                    ]
                },
                {
                    header: 'Module 5: Storage and Databases',
                    content: [
                        'Summarize the basic concept of storage and databases',
                        'Describe benefits of Amazon Elastic Block Store (Amazon EBS)',
                        'Describe benefits of Amazon Simple Storage Service (Amazon S3)',
                        'Describe the benefits of Amazon Elastic File System (Amazon EFS)',
                        'Summarize various storage solutions',
                        'Describe the benefits of Amazon Relational Database Service (Amazon RDS)',
                        'Describe the benefits of Amazon DynamoDB',
                        'Summarize various database services'
                    ]
                }
            ]
        }
    ]
};
const architectingOnAwsDetails = {
    description:
        'Architecting on AWS is for solutions architects, solution-design engineers, and developers who seek ' +
        'an understanding of Amazon Web Services (AWS) architecting. In this course, you will learn to identify services ' +
        'and features to build resilient, secure, and highly available IT solutions on the AWS Cloud.' +
        'Architectural solutions differ depending on industry, types of applications, and business size. ' +
        'AWS Authorized Instructors emphasize best practices by using the AWS Well-Architected Framework. ' +
        'They guide you through the process of designing optimal IT solutions based on real-life scenarios. ' +
        'The modules focus on account security, networking, compute, storage, databases, monitoring, automation, ' +
        'containers, serverless architecture, edge services, and backup and recovery. At the end of the course, ' +
        'you will practice building a solution and apply what you have learned. ',
    duration: '3 days',
    category: 'Media Services',
    skillLevel: SkillLevel.Intermediate,
    objectives: {
        header: 'In this course, you will learn to:',
        contents: [
            '    Identify AWS architecting basic practices.',
            '    Summarize the fundamentals of account security.',
            '    Identify strategies to build a secure virtual network that includes private and public subnets.',
            '    Practice building a multi-tier architecture in AWS.',
            '    Identify strategies to select the appropriate compute resources based on business use cases.',
            '    Compare and contrast AWS storage products and services based on business scenarios.',
            '    Compare and contrast AWS database services based on business needs.',
            '    Identify the role of monitoring, load balancing, and auto scaling responses based on business needs.',
            '    Identify and discuss AWS automation tools that will help you build, maintain, and evolve your infrastructure.',
            '    Discuss hybrid networking, network peering, and gateway and routing solutions to extend and secure your infrastructure.',
            '    Explore AWS container services for the rapid implementation of an infrastructure-agnostic, portable application environment.',
            '    Identify the business and security benefits of AWS serverless services based on business examples.',
            '    Discuss the ways in which AWS edge services address latency and security.',
            '    Explore AWS backup, recovery solutions, and best practices to ensure resiliency and business continuity. '
        ]
    },
    audience: {
        header: 'This course is intended for:',
        contents: [
            'Solution architects',
            'Solution-design engineers',
            'Developers who are seeking an understanding of AWS architecting',
            'Individuals who are seeking the AWS Solutions Architect-Associate certification '
        ]
    },
    activities:
        'This course includes presentations based on use cases. It also includes group discussions, demonstrations, assessments, and hands-on labs. ',
    prerequisites: {
        languages: ['Python', '.Net', 'Java'],
        cloud: ['AWS Technical Essentials', 'Working knowledge of AWS core services'],
        skillCenter: ['Completed AWS Cloud Practitioner Essentials', 'Completed AWS Technical Essentials '],
        general: [
            'Working knowledge of multi-tier architectures',
            'Familiarity with IP addressing',
            'Working knowledge of distributed systems'
        ]
    },
    modules: [
        {
            title: 'Day 1',
            modulesContent: [
                {
                    header: 'Module 1: Model Training',
                    content: [
                        'Choosing the right algorithm',
                        ' Formatting and splitting your data for training',
                        ' Loss functions and gradient descent forimproving your model',
                        ' Demo: Create a training job in Amazon SageMaker.'
                    ]
                },
                {
                    header: 'Module 2: Deployment',
                    content: [
                        'How to deploy, inference and monitor and your model on Amazon SageMaker',
                        ' Deploying ML at the edge',
                        ' Demo: Creating an AmazonSageMaker endpoint',
                        ' Course wrap-up.'
                    ]
                },
                {
                    header: 'Module 3: Model Training',
                    content: [
                        'Choosing the right algorithm',
                        ' Formatting andsplitting your data for training',
                        ' Loss functions and gradient descent forimproving your model',
                        ' Demo: Create a training job in Amazon SageMaker.'
                    ]
                }
            ]
        },
        {
            title: 'Day 2',
            modulesContent: [
                {
                    header: 'Module 4: Deployment',
                    content: [
                        'How to deploy, inference and monitor and your model on Amazon SageMaker.',
                        'Deploying ML at the edge.',
                        'Demo: Creating an Amazon SageMaker endpoint.',
                        'Course wrap-up.'
                    ]
                },
                {
                    header: 'Module 5: Model Training',
                    content: [
                        'Choosing the right algorithm.',
                        'Formatting and splitting your data for training.',
                        'Loss functions and gradient descent for improving your model.',
                        'Demo: Create a training job in Amazon SageMaker.'
                    ]
                }
            ]
        },
        {
            title: 'Day 3',
            modulesContent: [
                {
                    header: 'Module 6: Deployment',
                    content: [
                        'How to deploy, inference and monitor and your model on Amazon SageMaker',
                        'Deploying ML at the edge',
                        'Demo: Creating an Amazon SageMaker endpoint',
                        'Course wrap-up.'
                    ]
                },
                {
                    header: 'Module 7: Model Training',
                    content: [
                        'Choosing the right algorithm',
                        'Formatting and splitting your data for training',
                        'Loss functions and gradient descent for improving your model',
                        'Demo: Create a training job in Amazon SageMaker.'
                    ]
                }
            ]
        }
    ]
};
