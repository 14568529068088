import {Box, ColumnLayout, Container, Header, SpaceBetween} from '@amzn/awsui-components-react';
import {CourseProps} from '../../common/types';
import React from 'react';
import {createList} from '../../common/utils';

export const CourseDetails = (props: {course: CourseProps}) => {
    return (
        <SpaceBetween size='s'>
            <Header>Course description</Header>
            <Container>{props.course.description}</Container>
            <Header>Objectives</Header>
            <Container>
                {props.course.objectives?.header}
                {createList(props.course.objectives?.contents)}
            </Container>
            <Header>Intended audience</Header>
            <Container>
                {props.course.audience?.header}
                {createList(props.course.audience?.contents)}
            </Container>
            <Header>Activities</Header>
            <Container>{props.course.activities}</Container>
            <Header>Prerequisites</Header>
            <Container>
                <ColumnLayout borders='vertical' columns={2}>
                    <div>
                        <Header variant='h3'>Programming Language</Header>
                        {createList(props.course.prerequisites?.languages)}
                        <Header variant='h3'>General</Header>
                        {createList(props.course.prerequisites?.general)}
                    </div>
                    <div>
                        <Box margin={{left: 'm'}}>
                            <Header variant='h3'>Cloud Skills</Header>
                            {createList(props.course.prerequisites?.cloud)}
                            <Header variant='h3'>AWS Skill Center</Header>
                            {createList(props.course.prerequisites?.skillCenter)}
                        </Box>
                    </div>
                </ColumnLayout>
            </Container>
        </SpaceBetween>
    );
};
