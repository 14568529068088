import { AwsRumConfig } from 'aws-rum-web';
import { getNodeEnvironment, NodeEnvironment } from '../common/nodeEnvironment';

// Add the values from your personal Isengard account after deploying CDK
export const DEV_ALIAS = '';
export const DEV_RUM_GUEST_ROLE_ARN = '';
export const DEV_RUM_IDENTITY_POOL_ID = '';
export const DEV_RUM_APP_ID = '';

// Constant RUM values for all stages
export const RUM_APP_VERSION = '1.0.0';
export const RUM_APP_REGION = 'us-east-1';
export const RUM_APP_ENDPOINT = 'https://dataplane.rum.us-east-1.amazonaws.com';
export const RUM_APP_TELEMETRIES = ['errors', 'http', 'performance'];

export interface RumStageProps {
    appId: string;
    rumStageConfig: AwsRumConfig;
}

export const DEV_RUM: RumStageProps = {
    appId: DEV_RUM_APP_ID,
    rumStageConfig: {
        guestRoleArn: DEV_RUM_GUEST_ROLE_ARN,
        identityPoolId: DEV_RUM_IDENTITY_POOL_ID,
        sessionSampleRate: 0.5,
    },
};

export const BETA_RUM: RumStageProps = {
    appId: 'b72a2c26-d2ed-437e-8330-913b808c0714',
    rumStageConfig: {
        guestRoleArn: 'arn:aws:iam::364977308707:role/myClassRumGuestRole',
        identityPoolId: 'us-east-1:c22bb665-5639-48f5-b660-6e15211a596a',
        sessionSampleRate: 1.0,
    },
};

export const GAMMA_RUM: RumStageProps = {
    appId: 'b323077e-1005-4a2a-9559-8aecde4ce96c',
    rumStageConfig: {
        guestRoleArn: 'arn:aws:iam::831729637734:role/myClassRumGuestRole',
        identityPoolId: 'us-east-1:3323b5f9-b568-4018-8225-73a34fa68c7d',
        sessionSampleRate: 1.0,
    },
};

export const PROD_RUM: RumStageProps = {
    appId: '2ca392ca-7349-476e-acdb-4191c8b7a643',
    rumStageConfig: {
        guestRoleArn: 'arn:aws:iam::348575895610:role/myClassRumGuestRole',
        identityPoolId: 'us-east-1:1d25e7b4-2b7d-41f5-baca-2272a088e120',
        sessionSampleRate: 1.0,
    },
};

export function getCurrentRumStage(): RumStageProps {
    const env = getNodeEnvironment();
    switch (env) {
        case NodeEnvironment.BETA:
            return BETA_RUM;
        case NodeEnvironment.GAMMA:
            return GAMMA_RUM;
        case NodeEnvironment.PROD:
            return PROD_RUM;
        default:
            return DEV_RUM;
    }
}
