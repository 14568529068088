import {Box, Header, SpaceBetween, AppLayout} from '@amzn/awsui-components-react';
import './styles.scss';
import React from 'react';
import {useAuth} from '../../hooks/auth/useAuth';

export const AccessDenied = () => {
    const {authErrorMessage} = useAuth();
    const content = () => {
        return (
            <Box className='error-center'>
                <SpaceBetween size='m'>
                    <Box>
                        <img src='/public/error_page.svg' alt='error' />
                        <Header variant='h1'> Access Denied </Header>
                        <p>You do not have access to this page.</p>
                        <p>The following error occurred:</p>
                        <p>{authErrorMessage}</p>
                    </Box>
                    <br />
                </SpaceBetween>
            </Box>
        );
    };

    return <AppLayout content={content()} toolsHide navigationHide />;
};
