import { AppLayout, Badge, ContentLayout, Flashbar, Grid, Header, SpaceBetween, Spinner, Tabs } from '@amzn/awsui-components-react';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Navigation } from '../../components';
import { getBreadcrumbItemsFromCourse } from '../../common';
import './Course.scss';
import { CourseProps } from '../../common';
import { NotificationContext, DeliveryDetails, DurationIcon, LevelIcon, CategoryIcon, Breadcrumbs } from '../../components';
import { CourseDetails } from './CourseDetails';
import { CourseOutline } from './CourseOutline';
import { PAGE_NOT_FOUND_ROUTE } from '../../common';
import { CourseContext } from '../../components/course/CourseContext';
import { ProxyRegisteredBadge } from './ProxyRegisteredBadge';
import { ClassTypeBadge } from './ClassTypeBadge';
import { CourseBadges } from './Badges';

export const Course = () => {
    const { id } = useParams<{ id: string }>();
    const [course, setCourse] = useState<CourseProps>();
    const { courses } = useContext(CourseContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (courses.length) {
            const course = courses.find((course) => course.id === id);
            if (!course) {
                navigate(PAGE_NOT_FOUND_ROUTE);
            } else {
                setCourse(course);
                document.title = course.courseDetails.name;
            }
        }
    }, [courses, id, navigate]);

    const content = () => (
        <ContentLayout
            header={
                course ? (
                    <Grid gridDefinition={[{ colspan: { s: 8, xxs: 12 } }, { colspan: { s: 4, xxs: 12 } }]}>
                        <SpaceBetween size='xxl' direction='vertical'>
                            <CourseBadges item={course} />
                            <h1
                                aria-label='Class Title'
                                style={{
                                    height: '200px',
                                    fontSize: '64px',
                                    fontWeight: 900,
                                    margin: 0,
                                    lineHeight: '64px',
                                }}
                            >
                                {course.courseDetails.name}
                            </h1>
                            <SpaceBetween size='xxl' direction='horizontal' className='icons'>
                                <DurationIcon value={course.courseDetails.duration} size='large' />
                                <LevelIcon value={course.courseDetails.skillLevel} size='large' />
                                <CategoryIcon value={course.courseDetails.category} size='large' />
                            </SpaceBetween>
                        </SpaceBetween>
                        <div className='details'>
                            <div className='wrapper'>{course && <DeliveryDetails course={course} />}</div>
                        </div>
                    </Grid>
                ) : (
                    <Header>
                        <Spinner size='large' />
                        <div>Loading Course data</div>
                    </Header>
                )
            }
        >
            <div className='content'>
                <Tabs
                    tabs={[
                        {
                            label: 'About',
                            id: 'first',
                            content: <>{course && <CourseDetails course={course} />}</>,
                        },
                        {
                            label: 'Course outline',
                            id: 'second',
                            content: <>{course && <CourseOutline course={course} />}</>,
                        },
                    ]}
                />
            </div>
        </ContentLayout>
    );
    const { notifications } = useContext(NotificationContext);

    return (
        <AppLayout
            content={content()}
            navigation={<Navigation />}
            contentType={'wizard'}
            notifications={<Flashbar items={notifications} />}
            toolsHide={true}
            breadcrumbs={<Breadcrumbs items={getBreadcrumbItemsFromCourse(course?.courseDetails.name ?? '')} />}
        />
    );
};
