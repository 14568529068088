import { gql, useMutation } from '@apollo/client';
import { useAuth } from '../auth/useAuth';
import { recordError } from '../../clientMonitoring/rumClient';

export const REGISTER_LEARNING_ACTIVITY = gql`
    mutation registerLearningActivity($userId: ID!, $learningActivityId: ID!) {
        registerLearningActivity(userId: $userId, learningActivityId: $learningActivityId) {
            id
            registrationStatus
        }
    }
`;

export const useRegisterLearningActivity = (learningActivityId: string) => {
    const { user } = useAuth();
    return useMutation(REGISTER_LEARNING_ACTIVITY, {
        variables: { userId: user.id, learningActivityId: learningActivityId },
        onError: (error) => {
            recordError(error);
        },
    });
};
