import { gql, useQuery } from '@apollo/client';
import { recordError } from '../../clientMonitoring/rumClient';
import { useAuth } from '../auth/useAuth';

// startTimestamp is set to 2020-01-01 & endTimeStamp to 2030-01-01 to fetch all classes
export const LEARNING_ACTIVITIES_BY_USER_ID = gql`
    query learningActivitiesByUserId($userId: ID!) {
        learningActivities(learningActivitiesInput: { size: 1000, startTimestamp: 1577865600, endTimestamp: 1893484800 }) {
            pk
            activity_status
            class_size
            classrooms_student_url
            course_name
            delivery_language
            delivery_sessions {
                start_timestamp
                end_timestamp
            }
            delivery_timezone
            lms_type
            registrationCounter
            v_ilt_id
        }
        registrationsByUserId(userId: $userId, filter: { registrationModalityType: ILT }) {
            nodes {
                registrationStatus
                learningActivity {
                    pk
                }
            }
        }
        classAttendance(searchCriteria: { userId: $userId, includeRemoved: false }) {
            learningActivityAttendanceStatus
            learningActivity {
                pk
            }
        }
    }
`;

export const useLearningActivitiesByUserId = () => {
    const { user } = useAuth();
    return useQuery(LEARNING_ACTIVITIES_BY_USER_ID, {
        skip: !user.id,
        variables: { userId: user.id },
        notifyOnNetworkStatusChange: true,
        onError: (error) => {
            recordError(error);
        },
    });
};
