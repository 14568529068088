import React, { createContext, useReducer } from 'react';
import { FlashbarProps } from '@amzn/awsui-components-react/polaris/flashbar';

interface NotificationContextProps {
    notifications: FlashbarProps.MessageDefinition[];
    showErrorFlashbar: (msg: string) => void;
    showSuccessFlashbar: (msg: string) => void;
    showWarningFlashbar: (msg: string) => void;
    showInfoFlashbar: (msg: string) => void;
    clearFlashbar: () => void;
}

export const NotificationContext = createContext<NotificationContextProps>({
    notifications: [],
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    showErrorFlashbar: () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    showSuccessFlashbar: () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    showWarningFlashbar: () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    showInfoFlashbar: () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    clearFlashbar: () => {},
});

const FLASHBAR_ERROR_ADD = 'FLASHBAR_ERROR_ADD';
const FLASHBAR_SUCCESS_ADD = 'FLASHBAR_SUCCESS_ADD';
const FLASHBAR_WARNING_ADD = 'FLASHBAR_WARNING_ADD';
const FLASHBAR_INFO_ADD = 'FLASHBAR_INFO_ADD';
const FLASHBAR_CLEAR = 'FLASHBAR_CLEAR';

const reducer = (
    state: FlashbarProps.MessageDefinition[],
    action: { type: string; payload: { message: string; onDismiss: () => void } },
): FlashbarProps.MessageDefinition[] => {
    if (action.type === FLASHBAR_ERROR_ADD) {
        return [
            {
                ariaRole: 'alert',
                type: 'error',
                content: <span>{action.payload.message}</span>,
                dismissible: true,
                onDismiss: () => {
                    action.payload.onDismiss();
                },
            },
        ];
    }
    if (action.type === FLASHBAR_SUCCESS_ADD) {
        return [
            {
                ariaRole: 'status',
                type: 'success',
                content: <span>{action.payload.message}</span>,
                dismissible: true,
                onDismiss: () => {
                    action.payload.onDismiss();
                },
            },
        ];
    }
    if (action.type === FLASHBAR_WARNING_ADD) {
        return [
            {
                ariaRole: 'alert',
                type: 'warning',
                content: <span>{action.payload.message}</span>,
                dismissible: true,
                onDismiss: () => {
                    action.payload.onDismiss();
                },
            },
        ];
    }
    if (action.type === FLASHBAR_INFO_ADD) {
        return [
            {
                ariaRole: 'status',
                type: 'info',
                content: <span>{action.payload.message}</span>,
                dismissible: true,
                onDismiss: () => {
                    action.payload.onDismiss();
                },
            },
        ];
    }
    if (action.type === FLASHBAR_CLEAR) {
        return [];
    }
    return state;
};

export const NotificationProvider: React.FC = ({ children }) => {
    const [notifications, dispatch] = useReducer(reducer, []);

    const showErrorFlashbar = (msg: string) => {
        dispatch({
            type: FLASHBAR_ERROR_ADD,
            payload: {
                message: msg,
                onDismiss: () => {
                    clearFlashbar();
                },
            },
        });
    };
    const showSuccessFlashbar = (msg: string) => {
        dispatch({
            type: FLASHBAR_SUCCESS_ADD,
            payload: {
                message: msg,
                onDismiss: () => {
                    clearFlashbar();
                },
            },
        });
    };
    const showWarningFlashbar = (msg: string) => {
        dispatch({
            type: FLASHBAR_WARNING_ADD,
            payload: {
                message: msg,
                onDismiss: () => {
                    clearFlashbar();
                },
            },
        });
    };
    const showInfoFlashbar = (msg: string) => {
        dispatch({
            type: FLASHBAR_INFO_ADD,
            payload: {
                message: msg,
                onDismiss: () => {
                    clearFlashbar();
                },
            },
        });
    };
    const clearFlashbar = () => {
        dispatch({
            type: FLASHBAR_CLEAR,
            payload: {
                message: '',
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                onDismiss: () => {},
            },
        });
    };

    return (
        <NotificationContext.Provider
            value={{
                notifications,
                showErrorFlashbar,
                showSuccessFlashbar,
                showWarningFlashbar,
                showInfoFlashbar,
                clearFlashbar,
            }}
        >
            {children}
        </NotificationContext.Provider>
    );
};
