import {Box, Header, SpaceBetween, AppLayout} from '@amzn/awsui-components-react';
import './styles.scss';
import React from 'react';
import {DEFAULT_ROUTE} from '../../common/routes';
import {NavLink} from 'react-router-dom';

export class PageNotFound extends React.Component {
    private content() {
        return (
            <Box className='error-center'>
                <SpaceBetween size='m'>
                    <Box>
                        <img src='/public/error_page.svg' alt='error' />
                        <Header variant='h1'> Page Not Found </Header>
                        <p>
                            You might have typed the address incorrectly or used an outdated link. Check the link and
                            try again.
                        </p>
                    </Box>
                    <br />
                    <Box>
                        <SpaceBetween size='s'>
                            <NavLink to={DEFAULT_ROUTE}>Return to Home</NavLink>
                        </SpaceBetween>
                    </Box>
                </SpaceBetween>
            </Box>
        );
    }

    render() {
        return <AppLayout content={this.content()} toolsHide navigationHide />;
    }
}
