import React, { createContext, useContext, useEffect, useState } from 'react';
import { CourseProps, RegistrationStatus } from '../../common/';
import { useLearningActivitiesByUserId } from '../../hooks/learningActivity';
import { convertLearningActivitiesToCourses } from '../../common';
import { NotificationContext } from '../notifications/NotificationContext';

interface CourseContextProps {
    courses: CourseProps[];
    loading: boolean;
    updateCourseStatus: (classId: string, status: RegistrationStatus) => void;
}

export const CourseContext = createContext<CourseContextProps>({
    courses: [],
    loading: true,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    updateCourseStatus: () => {},
});

export const CourseProvider: React.FC = ({ children }) => {
    const [courses, setCourses] = useState<CourseProps[]>([]);
    const { showErrorFlashbar } = useContext(NotificationContext);
    const { error, data, loading } = useLearningActivitiesByUserId();
    useEffect(() => {
        if (error) {
            showErrorFlashbar(error.message);
        }
        if (data && !courses?.length) {
            const courses = convertLearningActivitiesToCourses(data);
            // Sort by class startTime, earliest first
            courses.sort((a, b) => a.schedule.classDays[0].startDateTime.getTime() - b.schedule.classDays[0].startDateTime.getTime());
            setCourses(courses);
        }
    }, [error, data]);

    const updateCourseStatus = (classId: string, status: RegistrationStatus) => {
        setCourses(courses.map((course) => (course.id === classId ? { ...course, registrationStatus: status, isProxy: false } : course)));
    };

    return (
        <CourseContext.Provider
            value={{
                courses,
                loading,
                updateCourseStatus,
            }}
        >
            {children}
        </CourseContext.Provider>
    );
};
