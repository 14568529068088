import {AwsRum, AwsRumConfig} from 'aws-rum-web';
import {
    getCurrentRumStage,
    RumStageProps,
    RUM_APP_ENDPOINT,
    RUM_APP_REGION,
    RUM_APP_TELEMETRIES,
    RUM_APP_VERSION
} from './rumConstants';

let rumClient: AwsRum;

export const rumInit = (allowCookies = false): void => {
    if (!rumClient) {
        const rumStage: RumStageProps = getCurrentRumStage();

        try {
            const config: AwsRumConfig = {
                ...rumStage.rumStageConfig,
                endpoint: RUM_APP_ENDPOINT,
                telemetries: RUM_APP_TELEMETRIES,
                allowCookies: allowCookies
            };

            rumClient = new AwsRum(rumStage.appId, RUM_APP_VERSION, RUM_APP_REGION, config);
        } catch (error) {
            // Ignore errors thrown during CloudWatch RUM web client initialization
        }
    }
};

export const recordError = (error: Error) => {
    if (rumClient) {
        rumClient.recordError(error);
    }
};
