import React from 'react';
import {Badge, Box, SpaceBetween} from '@amzn/awsui-components-react';
import './DashboardItem.scss';
import {CourseProps} from '../../common/types';
import {DeliveryDetails, DurationIcon, LevelIcon, CategoryIcon} from '../../components';
import {NavLink} from 'react-router-dom';

export const createCardDefinition = () => {
    return {
        header: (item: CourseProps) => (
            <SpaceBetween size='xs' direction='vertical'>
                <Badge color='green'>
                    <div aria-label='Class Type'>Virtual Classroom</div>
                </Badge>
                <h2 className='title' aria-label='Class Title'>
                    <NavLink to={`/course/${item.id}`}>{item.courseName}</NavLink>
                </h2>
            </SpaceBetween>
        ),
        sections: [
            {
                id: 'duration',
                content: (item: CourseProps) => <DurationIcon value={item.duration} />,
                width: 25
            },
            {
                id: 'skillLevel',
                content: (item: CourseProps) => <LevelIcon value={item.skillLevel} />,
                width: 35
            },
            {
                id: 'category',
                content: (item: CourseProps) => <CategoryIcon value={item.category} />,
                width: 40
            },
            {
                id: 'description',
                content: (item: CourseProps) => (
                    <Box>
                        <div className='description' aria-label='Description'>
                            {item.description}
                        </div>
                        <br />
                        <DeliveryDetails course={item} />
                    </Box>
                )
            }
        ]
    };
};
