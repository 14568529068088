import SideNavigation, {SideNavigationProps} from '@amzn/awsui-components-react/polaris/side-navigation';
import React from 'react';
import {APPLICATION_NAME, CERTIFICATION_PAGE} from '../../common/constants';
import {DEFAULT_ROUTE, PROFILE_ROUTE, SIGN_OUT_ROUTE} from '../../common/routes';
import {useNavigate} from 'react-router-dom';

export function Navigation() {
    const navigate = useNavigate();
    const onFollowHandler = (event: CustomEvent<SideNavigationProps.FollowDetail>) => {
        if (event.detail.href !== SIGN_OUT_ROUTE && event.detail.href !== CERTIFICATION_PAGE) {
            event.preventDefault();
            navigate(event.detail.href);
        }
    };

    return (
        <SideNavigation
            header={{href: DEFAULT_ROUTE, text: APPLICATION_NAME}}
            items={[
                {type: 'link', text: 'Home', href: DEFAULT_ROUTE},
                {type: 'link', text: 'Profile', href: PROFILE_ROUTE},
                {type: 'link', text: 'Sign Out', href: SIGN_OUT_ROUTE},
                {type: 'link', text: 'Certification', href: CERTIFICATION_PAGE, external: true}
            ]}
            activeHref={location.pathname}
            onFollow={onFollowHandler}
        />
    );
}
