import { gql, useMutation } from '@apollo/client';
import { recordError } from '../../clientMonitoring/rumClient';

export const SAVE_ILT_EVALUATION_RESPONSE = gql`
    mutation saveIltEvaluationResponse($transcriptId: ID!, $evaluationResponseId: ID!) {
        saveIltEvaluationResponse(transcriptId: $transcriptId, evaluationResponseId: $evaluationResponseId) {
            transcriptId
        }
    }
`;

export const useSaveIltEvaluationResponse = (transcriptId: string, evaluationResponseId: string) => {
    return useMutation(SAVE_ILT_EVALUATION_RESPONSE, {
        variables: { transcriptId: transcriptId, evaluationResponseId: evaluationResponseId },
        onError: (error) => {
            recordError(error);
        },
    });
};
