import {LOGOUT_URL} from './constants';

export const DEFAULT_ROUTE = '/';
export const HOME_ROUTE = '/home';
export const COURSE_DETAIL_ROUTE = '/course/:id';
export const PROFILE_ROUTE = '/profile';
export const TRANSCRIPT_ROUTE = '/transcript';
export const ACCESS_DENIED_ROUTE = '/accessdenied';
export const SIGN_OUT_ROUTE = `/signOut?redirect_uri=${LOGOUT_URL}`;
export const PAGE_NOT_FOUND_ROUTE = '/notFound';
export const MATCH_ALL_ROUTE = '*';
