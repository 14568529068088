export interface CourseProps {
    id: string;
    courseName: string;
    courseStatus: LearningActivityStatus;
    schedule: Schedule;
    description?: string;
    duration?: string;
    category?: string;
    registrationStatus?: RegistrationStatus;
    attendanceStatus?: AttendanceStatus;
    transcriptId?: string;
    skillLevel?: SkillLevel;
    classroomUrl?: string;
    nectoLink?: string;
    evaluationStatus?: EvaluationStatus;
    evaluationUrl?: string;
    objectives?: {
        header: string;
        contents: string[];
    };
    audience?: {
        header: string;
        contents: string[];
    };
    activities?: string;
    modules?: Module[];
    prerequisites?: {
        languages: string[];
        cloud: string[];
        skillCenter: string[];
        general: string[];
    };
}

export interface Registration {
    registrationStatus: RegistrationStatus;
    learningActivity: LearningActivity;
}

export interface ClassAttendance {
    learningActivityAttendanceStatus: AttendanceStatus;
    learningActivity: LearningActivity;
}

export interface Transcript {
    id: string;
    learningActivity: LearningActivity;
}

export interface Evaluation {
    evaluationUrl?: string;
    evaluationStatus: EvaluationStatus;
    learningActivity: LearningActivity;
}

export interface LearningActivity {
    pk: string;
    activity_status: LearningActivityStatus;
    class_size?: number;
    classrooms_student_url: string;
    course_name: string;
    delivery_language: string;
    delivery_sessions: DeliverySession[];
    delivery_timezone: string;
    lms_type?: LmsType;
    registrationCounter: number;
    v_ilt_id: string;
}

export interface DeliverySession {
    start_timestamp: number;
    end_timestamp: number;
}

export enum LearningActivityStatus {
    ACTIVE = 'Active',
    CANCELED = 'Canceled',
    COMPLETED = 'Completed'
}

export enum RegistrationStatus {
    CANCELED = 'CANCELED',
    REGISTERED = 'REGISTERED',
    WITHDRAWN = 'WITHDRAWN'
}

export enum AttendanceStatus {
    REMOVED = 'REMOVED',
    ATTENDED = 'ATTENDED',
    NO_SHOW = 'NO_SHOW',
    PARTIALLY_ATTENDED = 'PARTIALLY_ATTENDED',
    NOT_MARKED = 'NOT_MARKED'
}

export enum ClassStatus {
    CANCELED,
    COMPLETED,
    ENROLLED,
    IN_PROGRESS,
    NO_SHOW,
    PARTIALLY_ATTENDED,
    WITHDRAWN
}

export enum LmsType {
    Kiku = 'Kiku',
    myClass = 'myClass',
    CustomerLMS = 'CustomerLMS'
}

export enum EvaluationStatus {
    NOT_REQUIRED = 'NOT_REQUIRED',
    INCOMPLETE = 'INCOMPLETE',
    COMPLETED = 'COMPLETED'
}

export interface Schedule {
    classDays: ClassDay[];
    timezone: string;
    language: string;
    seatsRemaining: number;
}

export interface ClassDay {
    startDateTime: Date;
    endDateTime: Date;
}

export enum SkillLevel {
    Fundamental = 'Fundamental',
    Intermediate = 'Intermediate',
    Advanced = 'Advanced'
}

export interface Module {
    title: string;
    modulesContent: {
        header: string;
        content: string[];
    }[];
}

export type IconSize = 'small' | 'large';

export type IconProps = {
    value?: string;
    size?: IconSize;
};
