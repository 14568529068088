import { Container, Header, SpaceBetween } from '@amzn/awsui-components-react';
import { CourseProps } from '../../common/types';
import React from 'react';
import { createList } from '../../common/utils';

export const CourseDetails = (props: { course: CourseProps }) => {
    return (
        <SpaceBetween size='s'>
            <Header>Course description</Header>
            <Container>{props.course.courseDetails.description}</Container>
            <Header>Objectives</Header>
            <Container>
                In this course, you will learn to:
                {createList(props.course.courseDetails.objectives)}
            </Container>
            <Header>Intended audience</Header>
            <Container>
                This course is intended for:
                {createList(props.course.courseDetails.audience)}
            </Container>
            <Header>Prerequisites</Header>
            <Container>{props.course.courseDetails.prerequisites}</Container>
        </SpaceBetween>
    );
};
