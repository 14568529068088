import {CourseProps} from '../../common/types';
import {Container, Header, SpaceBetween} from '@amzn/awsui-components-react';
import React from 'react';
import {createList} from '../../common/utils';
import './CourseOutline.scss';

export const CourseOutline = (props: {course: CourseProps}) => {
    return (
        <SpaceBetween size='s'>
            {props.course.modules?.map((module) => (
                <SpaceBetween size='s' key={module.title}>
                    <Header>{module.title}</Header>
                    <Container>
                        {module.modulesContent?.map((content) => (
                            <div key={content.header}>
                                <Header variant='h3'>{content.header}</Header>
                                <hr className='module-separator' />
                                {createList(content.content)}
                            </div>
                        ))}
                    </Container>
                </SpaceBetween>
            ))}
        </SpaceBetween>
    );
};
